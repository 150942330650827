import React, { Component } from "react";
import { Col, Row, Container } from "reactstrap";
import logo from "../images/log1.png";
import EmailToContact from "./EmailToContact";
import FaFacebook from "react-icons/lib/fa/facebook";
import FaInstagram from "react-icons/lib/fa/instagram";
import FaWhatsapp from "react-icons/lib/fa/whatsapp";
import FaPhone from "react-icons/lib/fa/phone";

import androidLogo from "../images/android-logo.png";
import iosLogo from "../images/ios-logo.png";
import reactLogo from "../images/react-logo.png";
import mongoLogo from "../images/mongo-logo.png";
import mapboxLogo from "../images/mapbox-logo.png";
import $ from "jquery";

class FooterMain extends Component {
  componentDidMount() {
    var socialMediaFixedContente = document.getElementById(
      "social-media-fixed"
    );

    window.addEventListener('scroll', function () {
      var element = document.querySelector('#footer-bottom');
      var position = element.getBoundingClientRect();

      // checking for partial visibility
      if (position.top < window.innerHeight && position.bottom >= 0) {
        socialMediaFixedContente.classList.add('hide');
      } else {
        socialMediaFixedContente.classList.remove('hide');
      }
    });
  }

  render() {
    return (
      <div>
        <div id="social-media-fixed" className="social-media-footer">
          <a href="tel:72565925" className="social-icon-content">
            <FaPhone />
          </a>
          <a href="https://wa.me/59172565925" className="social-icon-content">
            <FaWhatsapp />
          </a>
          <a
            href="https://www.facebook.com/Mapps-Bolivia-165079877549166/"
            className="social-icon-content"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.instagram.com/mappsbo/"
            className="social-icon-content"
          >
            <FaInstagram />
          </a>
        </div>
        <div className="footer-os-list">
          <Container fluid>
            <Row>
              <Col xs="12">
                <img src={iosLogo} alt="" />
                <img src={androidLogo} alt="" />
                <img src={reactLogo} alt="" />
                <img src={mongoLogo} alt="" />
                <img src={mapboxLogo} alt="" />
              </Col>
            </Row>
          </Container>
        </div>
        <div id="footer-bottom" className="footer-content">
          <Container>
            <Row>
              <Col sm="2" className="logo-footer">
                <a href="/" className="logo-content">
                  <img src={logo} alt="" />
                  MAPPS<span className="cpy-r"></span>
                </a>
              </Col>
              <Col sm="2">
                <h5>Nosotros</h5>
                <ul>
                  <li>
                    <a href="/#welcome-section">Misión y Visión</a>
                  </li>
                  <li>
                    <a href="/contact">Contáctanos</a>
                  </li>
                </ul>
              </Col>
              <Col sm="2">
                <h5>Soluciones</h5>
                <ul>
                  <li>
                    <a href="/pdvs">Puntos de Venta</a>
                  </li>
                  <li>
                    <a href="https://aiudantes.com/">Servicios a domicilio</a>
                  </li>
                  <li>
                    <a href="https://colecta.app">Cobrazas y pagos por whastapp</a>
                  </li>
                  <li>
                    <a href="https://crucemad.app" >Aserraderos y barracas</a>
                  </li>
                  <li>
                    <a href="https://viviendaa.com/" >Gestion de residencias</a>
                  </li>
                  <li>
                    <a href="https://dirigelo.com/" >Seguimineto de Proyectos</a>
                  </li>
                </ul>
              </Col>
              <Col sm="2">
                <h5>Otros</h5>
                <ul>
                  <li>
                    <a href="/production/#production-admin">
                      Manejo de inventarios
                    </a>
                  </li>
                  <li>
                    <a href="/pdvs/#pdv-admin">Reportes</a>
                  </li>
                </ul>
              </Col>
              <Col sm="4">
                <EmailToContact extraClass="backgrond-grey" />
                <a href="tel:72565925" className="social-icon-content">
                  <FaPhone />
                </a>
                <a
                  href="https://wa.me/59172565925"
                  className="social-icon-content"
                >
                  <FaWhatsapp />
                </a>
                <a
                  href="https://www.facebook.com/Mapps-Bolivia-165079877549166/"
                  className="social-icon-content"
                >
                  <FaFacebook />
                </a>
                <a
                  href="https://www.instagram.com/mappsbo/"
                  className="social-icon-content"
                >
                  <FaInstagram />
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <p>© Copyright 2018-2024 mapps.com.bo. Reservados todos los derechos. Las distintas marcas comerciales pertenecen a sus respectivos propietarios.</p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default FooterMain;
