import React, { Component } from 'react';
import {  Col } from 'reactstrap';
import $ from 'jquery';
/*
buttonsList: [
    {extraClass: "c-accept", title: "Confirmar", description: {<FaCheckCircle />}},
    {image: designImage, title: "Imagen", description: "Diseno", path: "design"},
    {image: trackingImage, title: "Rastreo", description: "Localiza", path: "tracking"},
    {image: devicesImage, title: "Dispositivos", description: "Rastreado con carga solar", path: "devices"},
    {image: portfolioImage, title: "Portafolio", description: "Tenemos los siguientes demos", path: "portfolio"},
    {image: contatctImage, title: "Contáctanos", description: "Puedes mandarnos un mensaje para que sepamos que tipo de app quieres o que tipo de servicio requieres", path: "contact"}
]*/
class BottomButtonsApp extends Component {

    constructor (props) {
        super(props);

        this.getButtons = this.getButtons.bind(this);
        this.goToPage = this.goToPage.bind(this);
    }

    getButtons() {
        const buttonsList = [];

        
        var xsClass = 12 / this.props.buttonsList.length;
        
        this.props.buttonsList.forEach((button) => {
        if (button.title !== null) {       
            buttonsList.push(
                <Col xs={xsClass} className={`f-left ${button.extraClass}`} onClick={() => this.goToPage(button.toHide, button.toGo)}>            
                    {button.icon}
                    <p>{button.title}</p>
                </Col>
            );
            }
        });

        return buttonsList;
    }

    
    goToPage(toHide, toGo) {
        if(toHide && toGo) {
            $(`#${toHide}`).addClass('hide');
            $(`#${toGo}`).removeClass('hide');        
        }
    }
  
    render() {
        return (
            <div className={`${this.props.bodyClass ? this.props.bodyClass : ""}`}>
                {this.getButtons()}
            </div>          
        );  
    }
}

export default BottomButtonsApp;