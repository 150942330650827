import React from "react";
import $ from "jquery";
import { Button, Form, FormGroup, Input } from "reactstrap";

// Contact component render contact form
class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactName: "",
      contactEmail: this.getUrlParameter("email"),
      contactCompany: "",
      contactMessage: "",
      contactType: "ContactoGeneral"
    };
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleChangeContactType = this._handleChangeContactType.bind(this);
    this._handleChangeName = this._handleChangeName.bind(this);
    this._handleChangeEmail = this._handleChangeEmail.bind(this);
    this._handleChangeCompany = this._handleChangeCompany.bind(this);
    this._handleChangeMessage = this._handleChangeMessage.bind(this);
    this.displayGeneralContact = this.displayGeneralContact.bind(this);
  }

  componentDidMount() {
    if (this.getUrlParameter("hash")) {
      var target = $(`#${this.getUrlParameter("hash")}`);

      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: target.offset().top
          },
          1000
        );
    }
    if (this.getUrlParameter("contact-type")) {
      var contactType = this.getUrlParameter("contact-type");
      this.setState({ contactType });
    }
  }

  getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split("&"),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
  }

  displayGeneralContact() {
    $("#spinner-send").addClass("display-none");
    $("#message-content").removeClass("display-none");
    $("#message-sent").addClass("display-none");
  }

  displayGeneralContactError() {
    $("#spinner-send").addClass("display-none");
    $("#message-content").removeClass("display-none");
    $("#message-sent-error").addClass("display-none");
  }

  _handleChangeContactType(e) {
    this.setState({ contactType: e.target.value });
  }

  _handleChangeName(e) {
    this.setState({ contactName: e.target.value });
  }

  _handleChangeEmail(e) {
    this.setState({ contactEmail: e.target.value });
  }

  _handleChangeCompany(e) {
    this.setState({ contactCompany: e.target.value });
  }

  _handleChangeMessage(e) {
    this.setState({ contactMessage: e.target.value });
  }

  _handleSubmit(e) {
    e.preventDefault();
    this.setState({
      contactName: "",
      contactEmail: "",
      contactCompany: "",
      contactMessage: "",
      contactType: "generalContact"
    });
    var data = JSON.stringify({
      type: this.state.contactType,
      name: this.state.contactName,
      email: this.state.contactEmail,
      company: this.state.contactCompany,
      msg: this.state.contactMessage
    });
    $("#spinner-send").removeClass("display-none");
    $.ajax("https://mappspdvapi.herokuapp.com/mails/contactMapps", {
      data, //{action:'x',params:['a','b','c']}
      type: "POST",
      processData: false,
      contentType: "application/json", //typically 'application/x-www-form-urlencoded', but the service you are calling may expect 'text/json'... check with the service to see what they expect as content-type in the HTTP header.
      success: function (data) {
        // Success..
        $("#message-content").addClass("display-none");
        $("#message-sent").removeClass("display-none");
        $("#spinner-send").addClass("display-none");
        console.log("success");
      },
      // fail
      error: function (xhr, status, err) {
        $("#message-content").addClass("display-none");
        $("#message-sent-error").removeClass("display-none");
        $("#spinner-send").addClass("display-none");
        console.log("error");
      }
    });
  }

  render() {
    return (
      <div>
        <Form
          className="contact-form full-content-margin"
          onSubmit={this._handleSubmit}
          id="message-content"
        >
          <div>
            <h1>Contacto General</h1>
            <FormGroup>
              <Input
                type="select"
                name="select"
                id="select-question"
                value={this.state.contactType}
                onChange={this._handleChangeContactType}
              >
                <option value="ContactoGeneral">Contacto General</option>
                <option value="Precios">Pregunta por Precios</option>
                <option value="Demo">Exige tu demo</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="Nombre y Apellido"
                value={this.state.contactName}
                onChange={this._handleChangeName}
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Dirección de E-mail"
                value={this.state.contactEmail}
                onChange={this._handleChangeEmail}
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="company"
                id="company"
                placeholder="Empresa"
                value={this.state.contactCompany}
                onChange={this._handleChangeCompany}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="textarea"
                name="mensaje"
                id="mensaje"
                value={this.state.contactMessage}
                placeholder="Mensaje"
                onChange={this._handleChangeMessage}
                required
              />
            </FormGroup>
            <div className="submit-button-content">
              <div id="spinner-send" className="display-none pb-15">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <Button className="button fill-gold">Enviar</Button>

            </div>
          </div>
        </Form>
        <div id="message-sent" className="display-none contact-form full-content-margin">
          <h1>Gracias por su confianza</h1>
          <h2>Responderemos a la brevedad</h2>
          <div className="submit-button-content">
            <Button
              onClick={() => this.displayGeneralContact()}
              className="button fill-gold"
            >
              Nuevo Mensaje
            </Button>
          </div>
        </div>
        <div id="message-sent-error" className="display-none contact-form full-content-margin">
          <h1>Error al enviar su mensaje</h1>
          <h2>Porfavor Intente denuevo</h2>
          <div className="submit-button-content">
            <Button
              onClick={() => this.displayGeneralContactError()}
              className="button fill-gold"
            >
              Nuevo Mensaje
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
