import React, { Component } from "react";

import { NavHashLink as HashLink } from "react-router-hash-link";
import ReactWOW from "react-wow";

class LittleCard extends Component {
  render() {
    return (
      <ReactWOW animation="pulse" delay=".3s">
        <a className="col col-sm-4" href={this.props.goToLink} target={this.props.target}>
          <img src={this.props.imgSrc} alt="" />
          <h4>{this.props.title}</h4>
          <p>{this.props.text}</p>
        </a>
      </ReactWOW>
    );
  }
}

export default LittleCard;
