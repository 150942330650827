import React, { Component } from 'react';
import {  Table, Input } from 'reactstrap';
import $ from 'jquery';

class Calendar extends Component {

    constructor (props) {
        super(props);

        this.goToPage = this.goToPage.bind(this);
    }
    
    goToPage() {
        $(`#${this.props.componentToHide}`).addClass('hide');
        $(`#${this.props.componentToGo}`).removeClass('hide');   
    }
  
    render() {
        return (
            <div>
            <Input type="datetime-local" value=""/>   
            <Table bordered onClick={() => this.goToPage()}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Lun 12/03</th>
                  <th>Mar 13/03</th>
                  <th>Mie 14/03</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">8:00</th>
                  <td></td>
                  <td></td>
                  <td>Ocupado</td>
                </tr>
                <tr>
                  <th scope="row">9:00</th>
                  <td>Ocupado</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </div>
        );  
    }
}

export default Calendar;