import React, { Component } from 'react';
import ParticlesBg from './ParticlesBg';
import TopCarousel from './TopCarousel';
import LogoSeparator from '../LogoSeparator';
import $ from "jquery";
class MainContent extends Component {  
    getParticles() {
        return <ParticlesBg />;        
    }

    componentDidMount() {
        $("#go-welcome").click(() => {
            var target= $('#welcome-section');
            $('html, body').stop().animate({
                scrollTop: target.offset().top
             }, 1000);
        });        
    }

    render() {        
        return (
            <div>
                <TopCarousel />
                {this.props.particles ? this.getParticles() : ""}   
                <div className="space-25"></div>
                <div id="go-welcome" className="separator-click mt-30">
                    <LogoSeparator />
                    <div className="content-arrows">
                        <div className="arrows"></div>
                    </div>                
                </div>
                <div className="space-25"></div>
            </div>
        );
    }
}
  
export default MainContent;
  