import React, { Component } from 'react';
import { Row, Col, Container, Button, CardTitle, CardSubtitle, Input } from 'reactstrap';
import '../Demos.css';
import maskiphone7 from '../../../images/logo-demos/maskiphone7.png';
import brackets from '../../../images/logo-demos/brackets.jpg';
import familypromotion from '../../../images/logo-demos/familypromotion.png';
import mockupdentist from '../../../images/logo-demos/mockup-dentist.png';

import $ from 'jquery';
import FaClose from 'react-icons/lib/fa/close';

import Init from '../Init.js';
import MockupAppMap from '../MockupAppMap.js';
import MainContentHeader from '../MainContentHeader.js';

import BottomButtonsApp from './BottomButtonsApp.js';
import Calendar from './Calendar.js';
import RestartApp from '../RestartApp.js';

class DentalPacientApp extends Component {

  constructor (props) {
    super(props);

    this.closeMenu = this.closeMenu.bind(this);
  }

  closeMenu() {
    this.hideElement($(`#menu-app-d-id`));
    this.noHideElement($(`#appt`));
  }

  hideElement(element) {
    if(!element.hasClass('hide')) {
      element.addClass('hide');
    }
  }

  noHideElement(element) {
    if(element.hasClass('hide')) {
      element.removeClass('hide');
    }
  }


  render() {
    return (
      <div>              
        <Container fluid>
          <Row>     
            <Col xs="12" className="content-min-100vh">            
              <div className="app-content">              
                <div className="mask iphone7 app">
                  <div className="content ">
                    <Init 
                      componentId="init"
                      componentBg="custom-bg"
                      title="ECOS"
                      subTitle="Especialistas en consultas de salud dental"
                      startBtnClass="bg-blueb"
                      componentToGoId="appt" />                  

                    <MainContentHeader
                      componentId="appt"
                      menuId="menu-app-d-id"
                      componentBg="custom-bg"
                      componentHeaderClass="custom-header"
                      componentContentClass="custom-content"
                      headerText="Elija un Dia" 
                      componentContent={
                        <Calendar
                            componentToHide="appt"
                            componentToGo="new-appt"
                        />}
                      footer={
                          <BottomButtonsApp
                              componentToHide="appt"
                              btn1Show="appt"
                              btn2Show="services"
                              btn3Show="offers"
                          />
                        }
                      />

                    <MainContentHeader
                      componentId="services"
                      menuId="menu-app-d-id"
                      componentBg="custom-bg"
                      componentHeaderClass="custom-header"
                      componentContentClass="custom-content padding-lateral t-right"
                      headerText="Especialidades" 
                      componentContent={
                        <div>
                          <img src={brackets} alt="" />                      
                          Tratamiento 1
                          <p>Info Importante</p>
                          <p>Precio</p>
                          <p>Seciones</p>
                          Descripcion
                        </div>                                          
                      }
                      footer={
                        <BottomButtonsApp
                            componentToHide="services"
                            btn1Show="appt"
                            btn2Show="services"
                            btn3Show="offers"
                        />
                      }
                    />

                    <MainContentHeader
                      componentId="offers"
                      menuId="menu-app-d-id"
                      componentBg="custom-bg"
                      componentHeaderClass="custom-header"
                      componentContentClass="custom-content padding-lateral t-right"
                      headerText="Promociones" 
                      componentContent={
                        <div>
                          <img src={familypromotion} alt="" />                      
                          Promoción 1
                          <p>Info Importante</p>
                          <p>Precio</p>
                          <p>Seciones</p>
                          Descripcion
                        </div>                                          
                      }
                      footer={
                        <BottomButtonsApp
                            componentToHide="offers"
                            btn1Show="appt"
                            btn2Show="services"
                            btn3Show="offers"
                        />
                      }
                    />
                    
                    <MainContentHeader
                      goBackId="appt"
                      componentId="new-appt"
                      menuId="menu-app-d-id"
                      componentBg="custom-bg"
                      componentHeaderClass="custom-header"
                      componentContentClass="custom-content padding-lateral init"
                      headerText="Nueva Cita" 
                      componentContent={
                        <div>
                          <Input type="text" placeholder="Nombre Completo"/>
                          <Input type="select" name="select">
                                <option>Consulta</option>
                                <option>Cita Medica</option>
                                <option>Cotizacion</option>
                          </Input>
                          <Input type="textarea" placeHolder="Mensaje" name="text" />
                          <Button  className="bg-blueb">Reservar</Button>                                        
                        </div>
                      }  
                      footer={
                        <BottomButtonsApp
                            componentToHide="new-appt"
                            btn1Show="appt"
                            btn2Show="services"
                            btn3Show="offers"
                        />
                      }                 
                    />
                    <div id="menu-app-d-id" className="main-menu custom-bg white-bg hide dental-menu">
                      <div className="header custom-header">
                        <CardTitle>ECOS</CardTitle><span onClick={() => this.closeMenu()}><FaClose/></span>
                      </div>  
                      <div className="content custom-content init">
                        <CardSubtitle>Especialistas en Salud Dental</CardSubtitle>
                        <CardSubtitle>Direccion</CardSubtitle>
                        <p>Mapa</p>
                        <CardSubtitle>Doctor</CardSubtitle>
                        <p>Info Importante</p>
                        <CardSubtitle>Reservas</CardSubtitle>
                        <p>Nueva Cita</p>
                        <CardSubtitle>Servicios</CardSubtitle>
                        <p>Servicio 1</p>
                        <CardSubtitle>Promociones</CardSubtitle>
                        <p>Promoción Familiar</p>
                        <CardSubtitle>Acerca De</CardSubtitle>
                        <p>MAPPS</p>
                      </div>        
                    </div>  

                    <div className="mask-iphone7-img">
                      <img src={maskiphone7} alt=""/>
                      <RestartApp 
                        bodyClass="iphone7"
                        initId="init"
                        componentIds={[
                          "appt", 
                          "services",
                          "offers",
                          "new-appt"        
                        ]}
                      />
                    </div>
                  </div>                
                </div>                
              </div>        
            </Col>
          </Row>  
        </Container>  
        <MockupAppMap 
          headerSmallImage="Mockup de la Applicacion:"
          imageTitle="Mockup de la Para Medicos"
          modalClassName="content-img-modal "
          imgClassName="content-img-res custom-border"
          imageSrc={mockupdentist}
        />
      </div>            
    );
  }
}

export default DentalPacientApp;