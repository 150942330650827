import React, { Component } from 'react';
import {  Input } from 'reactstrap';
import $ from 'jquery';


class QuantitySectionApp extends Component {

    constructor (props) {
        super(props);

        this.goToPage = this.goToPage.bind(this);
    }
    
    goToPage() {
        $(`#${this.props.hideId}`).addClass('hide');
        $(`#${this.props.toGoId}`).removeClass('hide');        
    }
  
    render() {
        return (
            <div className={`quantity-section ${this.props.bodyClass ? this.props.bodyClass : ""}`}>
                <div className="info" onClick={() => this.goToPage()}>
                <h2>
                    {this.props.title}
                </h2>
                <p>
                    {this.props.description}
                </p>
                </div>
                <div className="quantity">
                    <Input type="number" placeholder="0"  defaultValue={this.props.defaultValue ? this.props.defaultValue : ""} ref={(input) => this.input = input} />
                </div>
            </div>
        );  
    }
}

export default QuantitySectionApp;