import React, { Component } from 'react'
import { Row, Col, Container } from 'reactstrap'
import '../Demos.css'

import FaPhone from 'react-icons/lib/fa/phone'
import FaShoppingCart from 'react-icons/lib/fa/shopping-cart'
import FaTruck from 'react-icons/lib/fa/truck'

import maskiphone7 from '../../../images/logo-demos/maskiphone7.png'
import products from '../../../images/logo-demos/products.jpg'
import ofertas from '../../../images/logo-demos/ofertas.jpg'

import Init from '../Init.js'
import MainContentHeader from '../MainContentHeader.js'
import MenuApp from '../MenuApp.js'
import RestartApp from '../RestartApp.js'

import MockupAppMap from '../MockupAppMap.js'
import CardApp from '../CardApp.js'
import BottomButtonsApp from '../BottomButtonsApp.js'

class MarketApp extends Component {

  render() {
    return (
      <div>              
        <Container fluid>
          <Row>     
            <Col xs="12" className="content-min-100vh">            
              <div className="app-content">              
                <div className="mask iphone7 app market">
                  <div className="content">
                    <Init 
                      componentId="init"
                      componentBg="custom-bg"
                      title="Tienda ROPA"
                      startBtnClass="bg-transparent btn-main"                      
                      subTitle="Lo llevamos a tu casa"
                      componentToGoId="main" />   

                    <MainContentHeader
                      componentId="main"
                      menuId="menu-app-ditributor"
                      componentBg="custom-bg"
                      componentHeaderClass="custom-header"
                      componentContentClass="custom-content"
                      headerText="TIENDA ROPA"
                      componentContent={
                        <div>
                            Bienvenido Te aconsejamos ver nuestras ofertas
                          <CardApp 
                            bodyClass="card-app"
                            imgScr={ofertas}
                            title="Ofertas"
                            hideComponentId="main"
                            componentTogo="load-truck"
                          />
                          <CardApp 
                            bodyClass="card-app"
                            imgScr={products}
                            title="Tienda"
                            hideComponentId="main"
                            componentTogo="stores-map"
                          />
                        </div>                                          
                      }
                      footer={
                        <BottomButtonsApp 
                          bodyClass="main-bottom-buttons bottom-btns none-bg"
                          buttonsList={[
                            {extraClass: "", title: "LLamanos", icon: <FaPhone />, toHide: "main", toGo: "main"},
                            {extraClass: "", title: "Compras", icon: <FaShoppingCart />, toHide: "main", toGo: "load-in-truck"},
                            {extraClass: "", title: "Pedido", icon: <FaTruck />, toHide: "main", toGo: "store-sold"},
                          ]}
                        />                
                      }
                    />               

                    <MenuApp 
                      componentId="menu-app-ditributor"
                    />

                    <div className="mask-iphone7-img">
                      <img src={maskiphone7} alt=""/>
                      <RestartApp 
                        bodyClass="iphone7"
                        initId="init"
                        componentIds={[                    
                        ]}
                      />
                    </div>
                  </div>                
                </div>                
              </div>        
            </Col>
          </Row>  
        </Container>  
        <MockupAppMap 
          headerSmallImage="Mockup de la Applicacion:"
          imageTitle="Mockup de la Para Medicos"
          modalClassName="content-img-modal "
          imgClassName="content-img-res custom-border"
        />
      </div>            
    );
  }
}

export default MarketApp;