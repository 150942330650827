import React, { Component } from 'react';

import { Row, Container } from 'reactstrap';

import LittleCard from './LittleCard';

class CardsList extends Component {

    constructor (props) {
        super(props);

        this.getCards = this.getCards.bind(this);
    }

    getCards() {
        const cards = [];

        this.props.cards.forEach((card) => {
        if (card.title !== null) {       
            cards.push(
                <LittleCard 
                    goToLink={card.goToLink}
                    title={card.title} 
                    text={card.text} 
                    imgSrc={card.imgSrc}  />
            );
            }
        });

        return cards;
    }

    render() {
        return (
            <Container className="three-cards">
                <Row>  
                    <div>
                        <h1>{this.props.title}</h1>
                    </div>
                    <div>
                    {this.getCards()}
                    </div>
                </Row>
            </Container>
        );
    }
}

export default CardsList;