import React, { Component } from "react";

import { Card, CardText, CardBody, CardTitle, CardSubtitle } from "reactstrap";

import { Col, Row, Container } from "reactstrap";
import $ from "jquery";

import EmailToContact from "../EmailToContact";
import ReactWOW from "react-wow";

class SingleMidCard extends Component {
  componentDidMount() {
    $(`#button-to-${this.props.scrollToId}`).click(() => {
      var target = $(`#${this.props.scrollToId}`);

      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: target.offset().top
          },
          1000
        );
    });
  }

  render() {
    return (
      <Container>
        <Row>
          <Col xs="12" id={this.props.cardId} className="full-height">
            <ReactWOW animation="pulse" delay=".3s">
              <Card className="single-card">
                <CardBody>
                  <Row>
                    <Col md="8" className="margin-0-auto text-center">
                      <CardSubtitle className="color-gold">{this.props.subTitle}</CardSubtitle>
                      <CardTitle>{this.props.title}</CardTitle>
                      <CardText>{this.props.text}</CardText>
                    </Col>
                    <Col xs="12">
                      <EmailToContact />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </ReactWOW>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default SingleMidCard;
