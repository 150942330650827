import React, { Component } from 'react';
import dentistAppointment from '../../../images/logo-demos/dentist-appointment.png';
import dentistServices from '../../../images/logo-demos/dentist-services.png';
import offers from '../../../images/logo-demos/offers.png';

import $ from 'jquery';


class DentalPacientApp extends Component {
  constructor (props) {
      super(props);

      this.goToComponent = this.goToComponent.bind(this);
  }

  goToComponent(componentToShow, selectedBtn) {
      $(`#${this.props.componentToHide}`).addClass('hide');
      $(`#${componentToShow}`).removeClass('hide');  
      this.noSelectedButton($('.appt-btn'));
      this.noSelectedButton($('.services-btn'));  
      this.noSelectedButton($('.offers-btn'));  
      this.selectedButton($(`.${selectedBtn}`));
  };

  selectedButton(element) {
    if(!element.hasClass('selected')) {
      element.addClass('selected');
    }
  }

  noSelectedButton(element) {
    if(element.hasClass('selected')) {
      element.removeClass('selected');
    }
  }
  
  render() {
    return (
        <div className="main-bottom-buttons">
            <div className="width-3 selected appt-btn" onClick={() => this.goToComponent(this.props.btn1Show, "appt-btn")}>
                <img src={dentistAppointment} alt="" />
            </div>
            <div className="width-3 services-btn" onClick={() => this.goToComponent(this.props.btn2Show, "services-btn")}>
                <img src={dentistServices}  alt=""  />                          
            </div>
            <div className="width-3 offers-btn" onClick={() => this.goToComponent(this.props.btn3Show, "offers-btn")}>
                <img src={offers}  alt=""  />                            
            </div>
        </div>            
    );
  }
}

export default DentalPacientApp;