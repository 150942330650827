import React, { Component } from 'react';
import MainContent from './MainContent';
import { Row, Col, Container } from 'reactstrap';

class TopContent extends Component {

  render() {
    return (
      <Container fluid className="full-height blackMain">
        <Container>
          <Row>
            <Col xs="12" id="top" >
              <div hidden={this.props.hideBackground} className=""></div>
              <MainContent particles={this.props.particles} />
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default TopContent;