import React, { Component } from 'react';
import TitleCard from '../TitleCard';

import LeftImageCard from '../LeftImageCard';
import InfoTwoContainer from '../InfoTwoContainer';
import InfoFourContainer from '../InfoFourContainer';

import sawmillDistributionImg from '../../images/sawmill-distribution.png';
import sawmillProductionImg from '../../images/sawmill-production.png';
import sawmillMain from '../../images/sawmill-main.png';
import inventory from '../../images/inventory.png';
import reportscente from '../../images/reportscente.png';
import apidata from '../../images/apidata.png';
import purchasesRegister from '../../images/purchases-register.png';
import tableSizeImg from '../../images/table-size.png';
import usersContentImg from '../../images/users-content.png';

import estatistics from '../../images/estatistics.png';

class Sawmills extends Component {

  render() {
    return (
      <div>
        <TitleCard
          contactType="Demo"
          btnLink={true}
          linkUrl="https://calendly.com/mappsbo/30min"
          containerClass="full-height"
          classBackground="blackMain"
          subTitle="Aserraderos"
          title="Controla la producción y distribución de MADERA desde donde estés"
          text="Plataforma de administración y control para ASERRADEROS, registra desde la compra de troncos, hasta la producción y distribución de madera, te ayudamos a
          llevar de manera eficiente y detallada los movimientos más relevantes de tu empresa."
          imgSrc={sawmillMain}
          linkText="Demo Gratis"
        />

        <LeftImageCard
          componentId="devices-pdv-section"
          imgRigth={true}
          subTitle="Registro Detallado"
          title="Producción diaria sincronizada"
          text="Esta sección se encarga de todos los procesos de producción en aserraderos, registrar entradas o compras de troncos, troncos cortados por dia,
          tiempos de corte por especie, tiempo de paro de actividades, romaneo de tablas por tamaño y el
          registro de paquetes producidos por aserradero."
          imgSrc={sawmillProductionImg}
        />

        <LeftImageCard
          componentId="sales-pdv-section"
          subTitle="Barracas"
          title="Control de Inventario y Venta de Madera"
          text="Registra el envío y venta de paquetes desde los aserraderos, 
          controla la llegada de tablas en las diferentes barracas, agrega diferentes clientes y detalla las cuentas por cobrar de las ventas unitarias o paquetes y todo desde tu celular."
          imgSrc={sawmillDistributionImg}
        />
        <InfoTwoContainer
          imgSrc={inventory}
          classBackground="black0"
          subTitle="Administración"
          text="Con este software para aserraderos controla todos los movimientos de tu empresa de manera efectiva, solo necesitas registrar las especies de troncos, los tamaños de tablas (madera) que vendes y
          los usuarios encargados de producción (Aserraderos) y de barracas (Ventas), con esto los usuarios pueden registrar la información de acuerdo a sus responsabilidades, 
          por último revisa la información y movimientos de tus diferentes empleados."
          linkText="Ir a información y precios"
          imgSrc2={reportscente}
          classBackground2="black0"
          subTitle2="Reportes"
          text2="Registro detallado de movimientos de: Compra de troncos, volumenes de troncos aserrados, producción diaria, tiempos de producción, efectividad de corte, reportes de paros de actividad, romaneo y corte en pies tablares (pt), 
          volumenes de troncos y tablas por aserradero, creación de paquetes con diferentes medidas, envio o venta de paquetes a clientes y barracas,
          volumenes de tablas en (pt) por barraca, ventas por barraca y todo esto desde donde estes."
          title="Manejo de datos"
          titleBig="Eficiente y Detallado"
          componentId="pdv-admin" />

        <InfoFourContainer
          imgSrc={inventory}
          classBackground="black1"
          cardsInfo={[{
            text: "Diferentes accesos",
            imgSrc: usersContentImg
          },
          {
            text: "Compras, Insumos y gastos",
            imgSrc: purchasesRegister
          }, {
            text: "Paquetes y Tamaños",
            imgSrc: tableSizeImg
          }, {
            text: "Estadísticas Personalizadas",
            imgSrc: estatistics
          }]}
          text="Ahorra tiempo, tienes diferentes maneras de administrar y controlar el negocio usando sólo las funciones que más te interesen."
          title="Control"
          titleBig="Diferentes ventajas incluidas"
          componentId="pdv-multi-info" />

        <LeftImageCard
          mainView={true}
          subTitle="No te compliques"
          title="Administra tu negocio fácil y desde "
          importanTitle="cualquier dispositivo"
          text="Puedes empezar controlando tus aserraderos y barracas con uno o varios usuarios en tiempo real."
          button={
            {
              text: "Demo Gratis",
              goToLink: "https://calendly.com/mappsbo/30min"
            }
          }

          imgSrc={apidata} />

      </div>
    );
  }
}

export default Sawmills;