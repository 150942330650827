import React from 'react';
import { NavHashLink as HashLink } from 'react-router-hash-link';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem
} from 'reactstrap';

import logo from '../images/log1.png';
import TopMenu from './TopMenu.js';
import FaClose from "react-icons/lib/fa/close";

export default class HeaderMenu extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.showPlatform = this.showPlatform.bind(this);
    this.hidePlatform = this.hidePlatform.bind(this);
    this.state = {
      isOpen: false,
      isHiddenPlatform: true
    };
  }

  componentDidMount() {
    var headerMenuHide = document.getElementById("header-menu-hide");
    var headerMenu = document.getElementById("header-menu");

    window.addEventListener("scroll", function () {
      if (this.innerWidth > 500) {
        if (this.pageYOffset > 170) {
          if (headerMenuHide.classList.contains("hide-menu")) {
            headerMenuHide.classList.remove('hide-menu');
            headerMenu.classList.add('hide-menu');
          }
        } else {
          if (!headerMenuHide.classList.contains("hide-menu")) {
            headerMenuHide.classList.add('hide-menu');
            headerMenu.classList.remove('hide-menu');
          }
        }
      }
      if (this.innerWidth > 300) {
        if (this.pageYOffset > 100) {
          if (headerMenuHide.classList.contains("hide-menu")) {
            headerMenuHide.classList.remove('hide-menu');
            headerMenu.classList.add('hide-menu');
          }
        } else {
          if (!headerMenuHide.classList.contains("hide-menu")) {
            headerMenuHide.classList.add('hide-menu');
            headerMenu.classList.remove('hide-menu');
          }
        }
      }
    });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  hidePlatform() {
    this.setState({
      isHiddenPlatform: true
    });
  }
  showPlatform() {
    this.setState({
      isHiddenPlatform: false
    });
  }

  render() {
    return (
      <div>
        <Navbar id="header-menu" className="custom-navbar fixed-top hide-less875" color="faded" light expand="md" >
          <NavbarBrand href="/" className="logo-content" >
            <img src={logo} alt="" className="pr-5p" /><p className="mt-15">
              MAPPS<span className="cpy-r"></span></p>
          </NavbarBrand>
          {!this.state.isOpen ? <NavbarToggler onClick={this.toggle} /> : 'x'}
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <HashLink smooth to="/#">Nosotros</HashLink>
              </NavItem>
              <NavItem onMouseOver={this.showPlatform} onMouseLeave={this.hidePlatform}>
                <TopMenu></TopMenu>
                {/*<NavLink to="#">Soluciones <span className="icon-title">&#x25BC;</span></NavLink>
                <ul className={this.state.isHiddenPlatform ? 'dropdownnav hidden' : 'dropdownnav'}>
                  <li>
                    <a href="/pdvs">Puntos de Venta</a>
                  </li>
                  <li>
                    <a href="https://crucemad.app" >Aserraderos y barracas</a>
                  </li>
                  <li>
                    <a href="https://colecta.app">Cobrazas y pagos por whastapp</a>
                  </li>
                  <li>
                    <a href="https://aiudantes.com/">Servicios a domicilio</a>
                  </li>
                  <li>
                    <a href="https://viviendaa.com/" >Gestion de residencias</a>
                  </li>
                  <li>
                    <a href="https://dirigelo.com/" >Seguimineto de Proyectos</a>
                  </li>
                </ul>
*/}
              </NavItem>
              <NavItem>
                <a href="/contact">Contáctanos</a>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <Navbar id="header-menu-hide" className="custom-navbar fixed-top hide-menu" color="faded" light expand="md" >
          <NavbarBrand href="/" className="logo-content" >
            MAPPS<span className="cpy-r"></span>
          </NavbarBrand>
          {!this.state.isOpen ? <NavbarToggler onClick={this.toggle} /> : <div className='text-left p-10-10 fs-21' onClick={this.toggle} ><FaClose /></div>}
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem onMouseOver={this.hidePlatform}>
                <HashLink smooth to="/#welcome-section">Nosotros</HashLink>
              </NavItem>
              <NavItem onMouseOver={this.showPlatform}>
                <TopMenu></TopMenu>

              </NavItem>
              <NavItem>
                <a href="/contact">Contáctanos</a>
              </NavItem>
            </Nav>

          </Collapse>

        </Navbar>
      </div>
    );
  }
}