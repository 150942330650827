import React, { Component } from 'react';
import $ from 'jquery';

import FaBars from 'react-icons/lib/fa/bars';
import FaAngleLeft from 'react-icons/lib/fa/angle-left';

class MainContentHeader extends Component {

    constructor (props) {
        super(props);

        this.goToMenu = this.goToMenu.bind(this);
        this.goBack = this.goBack.bind(this);
    }
    
    goToMenu() {
        $(`#${this.props.componentId}`).addClass('hide');
        $(`#${this.props.menuId}`).removeClass('hide');    
    };

    goBack() {
        $(`#${this.props.componentId}`).addClass('hide');
        $(`#${this.props.goBackId}`).removeClass('hide');    
    };
  
    render() {
        return (
            <div id={this.props.componentId} className={`main-menu ${this.props.componentBg} hide`}>
                <div className={`header ${this.props.componentHeaderClass}`}>
                    {this.props.goBackId ? 
                        <span onClick={() => this.goBack()} className="left"><FaAngleLeft/></span>
                    :
                        ""
                    }                    
                    {this.props.headerText}<span onClick={() => this.goToMenu()}><FaBars/></span>
                </div>
                <div className={`content ${this.props.componentContentClass}`}>
                    {this.props.componentContent}
                </div> 
                {this.props.footer ? this.props.footer : ""}                
            </div>
        );  
    }
}

export default MainContentHeader;