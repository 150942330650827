import React, { Component } from 'react';
import {  Container, Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';

class MockupAppMap extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }


    render() {
        return (
            <Container className="letter-content">
                <Row>
                    <Col sm="12">
                        <h2>
                            {this.props.headerSmallImage}
                        </h2>
                        <div className={this.props.imgClassName} onClick={this.toggle}>
                            <img src={this.props.imageSrc} alt="" />
                        </div>
                        <Modal isOpen={this.state.modal} modalTransition={{ timeout: 20 }} backdropTransition={{ timeout: 10 }}
                            toggle={this.toggle} className={this.props.modalClassName}>
                            <ModalHeader toggle={this.toggle}>{this.props.imageTitle}</ModalHeader>
                            <ModalBody>
                                <img src={this.props.imageSrc} alt="" />                                
                            </ModalBody>
                        </Modal>
                    </Col>
                </Row>
            </Container>
        );  
    }
}

export default MockupAppMap;