import React, { Component } from "react";

import { Col, Row, Container } from "reactstrap";
import { NavHashLink as HashLink } from "react-router-hash-link";
import ReactWOW from "react-wow";

class LeftImageCard extends Component {
  render() {
    return (
      <Container
        className={`card-img-left ${this.props.mainView ? "main-view" : ""}`}
        id={this.props.componentId}
      >
        <ReactWOW animation="pulse" delay=".3s">
          <Row>
            {!this.props.imgRigth ? (
              <Col sm="6" className="align-center image-card">
                <img src={this.props.imgSrc} alt="" />
              </Col>
            ) : (
                ""
              )}
            <Col sm="6">
              <h3>{this.props.subTitle}</h3>
              <h2>
                {this.props.title}
                {this.props.importanTitle ? (
                  <span>{this.props.importanTitle}</span>
                ) : (
                    ""
                  )}
              </h2>
              <p>{this.props.text}</p>
              {this.props.button ? (
                <div>
                  <a
                    href={this.props.button.goToLink}
                    className="app-button custom-btn"
                    target={this.props.button.target}
                  >
                    {this.props.button.text}
                  </a>
                </div>
              ) : (
                  ""
                )}
            </Col>
            {this.props.imgRigth ? (
              <Col sm="6" className="align-center image-card">
                <img src={this.props.imgSrc} alt="" />
              </Col>
            ) : (
                ""
              )}
          </Row>
        </ReactWOW>
      </Container>
    );
  }
}

export default LeftImageCard;
