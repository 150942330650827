import React, { Component } from "react";
import Slider from "react-slick";
import { NavHashLink as HashLink } from "react-router-hash-link";
import mainImage from "../../images/mainimage.png";
import EmailToContact from "../EmailToContact";

const items = [
  {
    caption: "Desarrollo",
    text: "Te ayudamos a crear tu aplicación o plataforma, ",
    importantText: "agenda un DEMO para resolver tus dudas",
    goToHash: "#apps-section"
  },
  {
    caption: "Soluciones",
    text: "Conoce todas nuestras herramientas, de control de negocios, ",
    importantText: "pagos online y mucho mas",
    goToHash: "#solutions-section"
  },
  {
    caption: "APIs",
    text: "Desarrollo e integración de APIs, desde",
    importantText: "pasarelas de pago, hasta plataformas de mensajeria",
    goToHash: "#apis-section"
  }
];

class TopCarousel extends Component {
  customPaging(e) {
    return (
      <div>
        <HashLink smooth to={`/${items[e].goToHash}`}>
          {items[e].caption}
        </HashLink>
        <div className="border-progress"></div>
      </div>
    );
  }

  render() {
    const slides = items.map(item => {
      return (
        <div className="slide-content">
          <h3>{item.caption}</h3>
          <p>
            {item.text}{" "}
            <span>{item.importantText ? item.importantText : ""}</span>{" "}
            {item.afterText ? item.afterText : ""}
          </p>
        </div>
      );
    });

    var settings = {
      arrows: false,
      dots: true,
      fade: true,
      infinite: true,
      autoplaySpeed: 5000,
      speed: 1500,
      slidesToShow: 1,
      autoplay: true,
      className: "main-slider",
      customPaging: this.customPaging
    };

    return (
      <div className="container main-carousel">
        <style>
          {`  
              .slick-dots {
                text-align: left;
                top: 252px;
              }
              .image-top { 
                top: 200px;
              }
              .image-top img {
                width: 100%;
              }
              .f-right {
                float: right;
              }
              .f-left {
                float: left;
              }
              .row-top {
                margin-top: -207px;
              }
              @media only screen and (max-width: 1332px) {
                .row-top {
                  margin-top: -126px;
                }
              }
              @media only screen and (max-width: 875px) {
                .slick-dots {
                  top: 200px;
                }
                .image-top { 
                  top: 72px;
                }
                .row-top {
                  margin-top: -117px;
                }
              }
              @media only screen and (max-width: 767px) {
                .slick-dots {
                  top: 144px;
                }
                .image-top { 
                  top: 72px;
                }
                .row-top {
                  margin-top: 135px;
                }
              }
              @media only screen and (max-width: 400px) {
                .slick-dots {
                  top: 180px;
                }
                .image-top { 
                  top: 63px;
                }
              }
              `}
        </style>
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6 image-top">
            <img src={mainImage} alt="Logo" />
          </div>
        </div>
        <div className="row row-top">
          <div className="col-md-6 z-index-front">
            <Slider {...settings}>{slides}</Slider>
            <div className="text-center-pad">
              <div>
                <a
                  href="https://calendly.com/mappsbo/30min"
                  className="app-button custom-btn free-button2"
                  target="_blank"
                >
                  Demo gratis
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopCarousel;
