import React, { Component } from 'react';
import restaurantmain from '../../images/restaurantmain.png';
import waiters from '../../images/waiters.png';
import kitchen from '../../images/kitchen.png';
import cashier from '../../images/cashier.png';
import TitleCard from '../TitleCard';
import LeftImageCard from '../LeftImageCard';
import InfoTwoContainer from '../InfoTwoContainer';
import InfoFourContainer from '../InfoFourContainer';
import RestaurantPrices from './restaurant-prices';

import inventory from '../../images/inventory.png';
import reportscente from '../../images/reportscente.png';
import kitchenProduct from '../../images/kitchenProduct.png';
import purchasesRegister from '../../images/purchases-register.png';
import reportscircle from '../../images/reportscircle.png';
import usersMulti from '../../images/users-multi.png';

class Restaurant extends Component {

  render() {
    return (
      <div>
         <TitleCard 
          btnHashLink={true}
          hashLinkUrl="#restaurant-prices"
          hashLinkText="Precios"
          classBackground="blackMain"
          subTitle="Restaurantes"
          title="Controla todos los pedidos y a tus empleados" 
          text="Comienza creando tu menú con diferentes tipos de productos, descripciones y precios, puedes controlar varios meseros y relacionarlos con la cocina, 
          esto incluye manejo de inventario, registro de ordenes para cocina, registro de ordenes listas, registro de NIT y nombre del cliente para la factura, cada usuario 
          es independiente, es decir, hay usuarios para cocina como usuarios para meseros y cajeros, estos usuarios pueden conectarse simultáneamente desde cualquier dispositivo." 
          imgSrc={restaurantmain}
        />

        <LeftImageCard 
          componentId="waiters"
          imgRigth={true}
          subTitle="Meseros" 
          title="Registro de pedidos, ordenes y cobros" 
          text="Estos usuarios tienen como responsabilidad registrar pedidos para las mesas del restaurante y enviar el pedido a la cocina, 
          una vez que el pedido esta preparado en la cocina el mesero recibe un aviso para recoger la orden y luego llevarla a la mesa, por otro lado estos pueden 
          realizar cobros de las mesas pidiendo el numero de NIT y nombre para la factura, todos los registros se pueden realizar con un smartphone o tablet." 
          imgSrc={waiters} 
        /> 

        <LeftImageCard 
          componentId="kitchen"
          subTitle="Cocina" 
          title="Recibir y completar de ordenes de meseros" 
          text="Este usuario o modulo recibe los pedidos de los meseros, cada ves que un mesero realiza un pedido, este usuario (cocina) muestra en la pantalla el nuevo pedido e imprime un 
          ticket con la información del pedido y para que mesa es, 
          una vez preparado el pedido puede enviar un aviso al mesero para que recoja la orden y la lleve a su mesa,
          este usuario (cocina) necesita una laptop o pc conectada a una impresora para la generación de tickets, si
          los tickets no son necesarios puede conectarse desde cualquier dispositivo.
          " 
          imgSrc={kitchen} 
        /> 

        <LeftImageCard 
          componentId="cashier"
          imgRigth={true}
          subTitle="Cajero" 
          title="Usuario de generación de facturas" 
          text="Este usuario recibe la información de los pedidos completados por los meseros con el NIT y nombre para la factura del cliente, puede generar facturas y 
          tiene toda la información de los pedidos y ventas del día realizadas por los meseros y puede generar toda la información requerida para los cierres de caja,
          este usuario (cajero) necesita una laptop o pc y una impresora para generar las facturas.
          " 
          imgSrc={cashier} 
        /> 

        <InfoTwoContainer 
          imgSrc={inventory}
          classBackground="black0"
          subTitle="Administración" 
          text="Cuentas con uno o varios usuarios administradores, comienza registrando el menú de productos disponibles para el restaurante, luego activa los meseros en smartphones o tablets, 
          la cocina en una computadora con impresora para tickets y por ultimo el cajero a una computadora conectada a una impresora para la emisión de facturas,
          con esto tendrás todo el control de todos tus empleados en tiempo real."
          linkText="Ir a información y precios"
          imgSrc2={reportscente}
          classBackground2="black0"
          subTitle2="Reportes" 
          text2="Toda la información del negocio en un solo lugar, puedes generar reportes de movimientos de inventario por restaurantes, 
          ventas y pedidos realizados por los meseros, pedidos cancelados, facturas emitidas,
          gastos, compras del negocio, cierres diarios, estadísticas por productos y mucho más.
          "
          title="Manejo"
          titleBig="De todo tu negocio en tus manos"
          componentId="restaurant-admin" />

        <InfoFourContainer 
          imgSrc={inventory}
          classBackground="black1"
          cardsInfo={[{
            text: "Menú de productos",
            imgSrc: kitchenProduct
          },
          {
            text: "Compras y gastos",
            imgSrc: purchasesRegister
          },{
            text: "Diferentes Usuarios",
            imgSrc: usersMulti
          },{
            text: "Estadísticas",
            imgSrc: reportscircle
          }]}
          text="Ten toda la información relevante de tu restaurante en tus manos, 
          tienes todos los registros de tu negocio accesibles desde cualquier dispositivo y en cualquier lugar"
          title="Administración"
          titleBig="Diferentes ventajas de manejo"
          componentId="restaurant-multi-info"/>

        <RestaurantPrices 
          componentId="restaurant-prices"
          title="Precios"
          subTitle="Restaurantes"
          />
      </div>
    );
  }
}

export default Restaurant;