import React, { Component } from 'react';
import $ from 'jquery';

import map1 from '../../images/logo-demos/map1.png';
import FaMapMarker from 'react-icons/lib/fa//map-marker';

class MapMarkers extends Component {

    constructor (props) {
        super(props);

        this.goToMarker = this.goToMarker.bind(this);
    }

    goToMarker() {
        $(`#${this.props.toHide}`).addClass('hide');
        $(`#${this.props.goToMarker}`).removeClass('hide');    
    };
  
    render() {
        return (
            <div className="map-content">
                <img src={map1} alt=""/>
                <FaMapMarker onClick={() => this.goToMarker()} />
                <FaMapMarker onClick={() => this.goToMarker()}  />
                <FaMapMarker onClick={() => this.goToMarker()}  />
            </div>
        );  
    }
}

export default MapMarkers;