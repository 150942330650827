import React, { Component } from 'react';

import { Row, Container } from 'reactstrap';
import reportscircle from '../../images/reportscircle.png';
import pdvperson from '../../images/pdvperson.png';

import waitressattengding from '../../images/waitressattengding.png';
import warehouse from '../../images/warehouse.png';
import factoryhouse from '../../images/factoryhouse.png';
import platform from '../../images/platform.png';

import LittleCard from '../LittleCard';

class ThreeCards extends Component {
    render() {
        return (
            <Container className="three-cards">
                <Row>
                    <LittleCard
                        goToLink="https://www.mappspdv.app/"
                        target="_blank"
                        title="Puntos de venta"
                        text="Controla sucursales con usuarios independientes."
                        imgSrc={pdvperson} />

                    <LittleCard
                        goToLink="https://colecta.app/"
                        target="_blank"
                        title="comunicación y cobranzas"
                        text="Automatiza el envio información, recordatorios de pago y recibe pagos de tus clientes"
                        imgSrc={warehouse} />

                    <LittleCard
                        goToLink="https://dirigelo.com/"
                        target="_blank"
                        title="Seguimiento de proyectos"
                        text="Controla las ganancias de cada proyecto que realizas"
                        imgSrc={waitressattengding} />

                    <LittleCard
                        goToLink="https://crucemad.app/"
                        target="_blank"
                        title="Barracas y aserraderos"
                        text="Administra la producción, distribución y comercialización de madera."
                        imgSrc={factoryhouse} />

                    <LittleCard
                        goToLink="https://viviendaa.com/"
                        target="_blank"
                        title="Residencias"
                        text="Gestion y comunicación de condominios, edificios y urbanizaciones"
                        imgSrc={reportscircle} />

                    <LittleCard
                        goToLink="https://aiudantes.com/"
                        target="_blank"
                        title="Servicios a domicilio"
                        text="Empieza a ofertar servicios a domicilio y genera mas ganancias"
                        imgSrc={platform} />
                </Row>
            </Container>
        );
    }
}

export default ThreeCards;