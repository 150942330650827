import React, { Component } from "react";

import { Card, CardImg, CardText, CardBody, CardSubtitle } from "reactstrap";

import { Col, Row, Container } from "reactstrap";
import ReactWOW from "react-wow";

class InfoTwoContainer extends Component {
  render() {
    return (
      <Container fluid id={this.props.componentId}
        className={`main-card full-height ${this.props.classBackground} bottom-20vh`}>
        <Container>
          <Row>
            <Col xs="12" className="titles">
              <h4>{this.props.title}</h4>
              <h2>{this.props.titleBig}</h2>
            </Col>
            <Col md="6">
              <ReactWOW animation="pulse" delay=".3s">
                <Card className="single-card no-title">
                  <CardBody>
                    {this.props.subTitle ? (
                      <CardSubtitle>{this.props.subTitle}</CardSubtitle>
                    ) : (
                        ""
                      )}
                    <CardImg top src={this.props.imgSrc} />
                    <CardText>{this.props.text}</CardText>
                  </CardBody>
                </Card>
              </ReactWOW>
            </Col>
            <Col md="6">
              <ReactWOW animation="pulse" delay=".3s">
                <Card className="single-card no-title">
                  <CardBody>
                    {this.props.subTitle2 ? (
                      <CardSubtitle>{this.props.subTitle2}</CardSubtitle>
                    ) : (
                        ""
                      )}
                    <CardImg top src={this.props.imgSrc2} />
                    <CardText>{this.props.text2}</CardText>
                  </CardBody>
                </Card>
              </ReactWOW>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default InfoTwoContainer;
//<a id={`button-to-${this.props.goToId}`} className="activo">Ver Todo</a>
