import React, { Component } from 'react';
import TitleCard from '../TitleCard';
import marketingMain from '../../images/marketing-main.png';

import LeftImageCard from '../LeftImageCard';
import map1 from '../../images/map1.png';

class Support extends Component {

  render() {
    return (
      <div>
        <TitleCard 
          classBackground="black1"
          subTitle="Soporte"
          title="Nunca estes solo" 
          text="Nosotros estaremos contigo para asegurarnos del crecimiento de tu negocio y de satisfaces todas tus necesidades." 
          imgSrc={marketingMain}
        />

        <LeftImageCard 
          imgRigth={true}
          subTitle="Apps" 
          title="Sincronizacion" 
          text="Te ofrecemos la posibilidad de manejar to applicación y tu web de manera conjunta.." 
          imgSrc={map1} 
        /> 

        <LeftImageCard 
          subTitle="Cloud" 
          title="Servicio de almacenamiento" 
          text="Tendras la posibilidad de modiifcar, dar permisos y controlar todos los movimientos de tus datos y nosortros nos aseguramos de proveerlos cuando los necesites y de dar la seguridad de que estaran protegidos." 
          imgSrc={map1} 
        /> 
        
      </div>
    );
  }
}

export default Support;