import React, { Component } from "react";

import { Row, Container, Col, Input, Collapse, Button } from "reactstrap";
import FaChevronCircleDown from "react-icons/lib/fa/chevron-circle-down";
import { NavHashLink as HashLink } from "react-router-hash-link";

import EmailToContact from "../EmailToContact";

class ProductionPrices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminUsers: 1,
      factories: 1,
      pdvs: 1,
      pdvUsers: 1,
      collapseAdmin: false,
      collapseProductions: false,
      collapsePdv: false,
      collapseSalesUser: false,
      priceAdmin: 18,
      pricePdv: 18,
      priceFactory: 36,
      pricePdvUser: 17,
      selectedPriceTime: 0,
      pricesTime: [
        {
          priceAdmin: 18,
          priceFactory: 36,
          pricePdv: 18,
          pricePdvUser: 18
        },
        {
          priceAdmin: 16,
          priceFactory: 30,
          pricePdv: 16,
          pricePdvUser: 16
        },
        {
          priceAdmin: 16,
          priceFactory: 24,
          pricePdv: 16,
          pricePdvUser: 16
        }
      ],
      priceTypes: ["1 Mes", "6 Meses", "1 Año"]
    };
    this.toggle = this.toggle.bind(this);
    this._handleAdminUser = this._handleAdminUser.bind(this);
    this._handlePdvs = this._handlePdvs.bind(this);

    this._handlePdvUsers = this._handlePdvUsers.bind(this);
    this._handleFactories = this._handleFactories.bind(this);
    this.selectPrice = this.selectPrice.bind(this);
  }

  toggle(name, value) {
    const valueToSet = JSON.parse(`{ "${name}": ${value} }`);

    this.setState(valueToSet);
  }

  _handleAdminUser(e) {
    if (e.target.value !== null && e.target.value !== '' && e.target.value < 1) {
      this.setState({ adminUsers: 1 });
    } else {
      this.setState({ adminUsers: e.target.value });
    }
  }

  _handleFactories(e) {
    if (e.target.value !== null && e.target.value !== '' && e.target.value < 1) {
      this.setState({ factories: 1 });
    } else {
      this.setState({ factories: e.target.value });
    }
  }

  _handlePdvs(e) {
    if (e.target.value !== null && e.target.value !== '' && e.target.value < 1) {
      this.setState({ pdvs: 1 });
    } else if (e.target.value !== null && e.target.value !== '' && this.state.pdvUsers < e.target.value) {
      this.setState({ pdvs: e.target.value, pdvUsers: e.target.value });
    } else {
      this.setState({ pdvs: e.target.value });
    }
  }

  _handlePdvUsers(e) {
    if (e.target.value !== null && e.target.value !== '' && e.target.value < 1) {
      this.setState({ pdvUsers: 1 });
    } else {
      this.setState({ pdvUsers: e.target.value });
    }

    if (e.target.value !== null && e.target.value !== '' && this.state.pdvs > e.target.value) {
      this.setState({ pdvUsers: this.state.pdvs });
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.selectPrice(0);
    }, 1)
  }

  activeButtonClass(i) {
    const className = i == this.state.selectedPriceTime ? "blue-lite" : "";

    return `app-button custom-btn ${className}`;
  }

  selectPrice(value) {
    this.setState({
      selectedPriceTime: value,
      priceAdmin: this.state.pricesTime[value].priceAdmin,
      pricePdvUser: this.state.pricesTime[value].pricePdvUser,
      priceFactory: this.state.pricesTime[value].priceFactory,
      pricePdv: this.state.pricesTime[value].pricePdv
    });
  }
  

  getTotalPrice() {
    let timeValue =
      this.state.selectedPriceTime == 1
        ? 6
        : this.state.selectedPriceTime == 2
        ? 12
        : 1;

    return (
      (this.state.pricePdv * this.state.pdvUsers +
        this.state.priceAdmin * this.state.adminUsers +
        this.state.priceFactory * this.state.factories +
        this.state.pricePdvUser * this.state.pdvs) *
      timeValue
    );
  }

  getTotalPriceNoDiscount() {
    let timeValue =
      this.state.selectedPriceTime == 1
        ? 6
        : this.state.selectedPriceTime == 2
        ? 12
        : 1;

    return (
      (this.state.pricesTime[0].pricePdv * this.state.pdvUsers +
        this.state.pricesTime[0].priceAdmin * this.state.adminUsers +
        this.state.pricesTime[0].priceFactory * this.state.factories +
        this.state.pricesTime[0].pricePdvUser * this.state.pdvs) *
      timeValue
    );
  }

  render() {
    return (
      <Container
        fluid
        className={`prices-content ${this.props.classBackground}`}
        id={this.props.componentId}
      >
        <Row>
          <Col md="12">
            <h5>{this.props.subTitle}</h5>
            <h3>{this.props.title}</h3>
            <p>
              Tenemos diferentes precios y tipos de pago, puedes elegir entre 3
              opciones de precios que varían en base a los meses que deseas
              pagar, calcula el precio del servicio registrando la información
              de cuantos usuarios administradores, fábrica, sucursales y vendedores que necesitas.
            </p>
            <p>Selecciona una opción de pago:</p>
          </Col>
          <Col md="12">
            <Button
              className={this.activeButtonClass(0)}
              onClick={() => {
                this.selectPrice(0);
              }}
            >
              1 Mes
            </Button>
            <Button
              className={this.activeButtonClass(1)}
              onClick={() => {
                this.selectPrice(1);
              }}
            >
              6 Meses
            </Button>
            <Button
              className={this.activeButtonClass(2)}
              onClick={() => {
                this.selectPrice(2);
              }}
            >
              1 Año
            </Button>
          </Col>
          <Col md="12">
            <h2>
              <span className="color-black-main">Total</span>
              {this.getTotalPrice()}
              <span>$</span>
            </h2>
            <div className="discount-price-type">
              Ahorras
              <span>
                {this.getTotalPriceNoDiscount() - this.getTotalPrice()}
              </span>
              $
            </div>
          </Col>
          <Col md="6">
            <Container fluid>
              <Row>
                <Col xs="6" className="padding-lr-5">
                  <div className="border-card input-price">
                    <h4>Administradores</h4>
                    <Container fluid>
                      <Row>
                        <Col xs="12">
                          <h2>
                            {this.state.priceAdmin * this.state.adminUsers}
                            <span>$</span>
                          </h2>
                          <h5>Usuarios</h5>
                          <Input
                            type="number"
                            onChange={this._handleAdminUser}
                            min="1"
                            placeholder="Usuarios administradoers"
                            value={this.state.adminUsers}
                            required
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div
                      className="collapse-button"
                      onClick={() =>
                        this.toggle("collapseAdmin", !this.state.collapseAdmin)
                      }
                    >
                      <span>Ver más</span> {" "}
                      <FaChevronCircleDown />
                    </div>
                    <Collapse isOpen={this.state.collapseAdmin}>
                      <ul className="text-left">
                        <li>Usuarios independientes</li>
                        <li>Permiso administrativo</li>
                        <li>Registro de gastos</li>
                        <li>Registro de compras</li>
                        <li>Manejo de inventario por fábrica</li>
                        <li>Manejo de inventario por sucursales</li>
                        <li>Control de tipos de usuario</li>
                        <li>Activar usuarios para diferentes fábrica</li>
                        <li>Activar usuarios para diferentes sucursales</li>
                        <li>Cambio de contraseñas</li>
                        <li>Cierres diarios</li>
                        <li>Generación de reportes</li>
                      </ul>
                    </Collapse>
                  </div>
                </Col>
                <Col xs="6" className="padding-lr-5">
                  <div className="border-card input-price">
                    <h4>Fábricas</h4>
                    <Container fluid>
                      <Row>
                        <Col xs="12">
                          <h2>
                            {this.state.priceFactory * this.state.factories}
                            <span>$</span>
                          </h2>
                          <h5>Fábricas</h5>
                          <Input
                            type="number"
                            min="1"
                            onChange={this._handleFactories}
                            placeholder="Fábricas y Usuarios"
                            value={this.state.factories}
                            required
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div
                      className="collapse-button"
                      onClick={() =>
                        this.toggle(
                          "collapseProductions",
                          !this.state.collapseProductions
                        )
                      }
                    >
                      <span>Ver más</span> {" "}
                      <FaChevronCircleDown />
                    </div>
                    <Collapse isOpen={this.state.collapseProductions}>
                      <ul className="text-left">
                        <li>Control de fábricas</li>
                        <li>Desarrollo de productos</li>
                        <li>Inventarios de Insumos</li>
                        <li>Inventarios de productos</li>
                        <li>Control de perdidas</li>
                        <li>Cálculos de desarrollo de productos</li>
                        <li>Envío de productos a sucursal</li>
                      </ul>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>

          <Col md="6">
            <Container fluid>
              <Row>
                <Col xs="6" className="padding-lr-5">
                  <div className="border-card input-price">
                    <h4>Puntos de venta</h4>
                    <Container fluid>
                      <Row>
                        <Col xs="12">
                          <h2>
                            {this.state.pricePdv * this.state.pdvs}
                            <span>$</span>
                          </h2>
                          <h5>Sucursales</h5>
                          <Input
                            type="number"
                            onChange={this._handlePdvs}
                            min="1"
                            placeholder="Sucursales"
                            value={this.state.pdvs}
                            required
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div
                      className="collapse-button"
                      onClick={() => {
                        this.toggle("collapsePdv", !this.state.collapsePdv);
                      }}
                    >
                      <span>Ver más</span> {" "}
                      <FaChevronCircleDown />
                    </div>
                    <Collapse isOpen={this.state.collapsePdv}>
                      <ul className="text-left">
                        <li>Puntos de venta</li>
                        <li>Control de vendedores</li>
                        <li>Reportes de ventas y cargas por sucursales</li>
                        <li>Lista de usuarios activos</li>
                      </ul>
                    </Collapse>
                  </div>
                </Col>
                <Col xs="6" className="padding-lr-5">
                  <div className="border-card input-price">
                    <h4>Vendedores</h4>
                    <Container fluid>
                      <Row>
                        <Col xs="12">
                          <h2>
                            {this.state.pricePdvUser * this.state.pdvUsers}
                            <span>$</span>
                          </h2>
                          <h5>Usuarios</h5>
                          <Input
                            type="number"
                            min="1"
                            onChange={this._handlePdvUsers}
                            placeholder="Usuarios Vendedores"
                            value={this.state.pdvUsers}
                            required
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div
                      className="collapse-button"
                      onClick={() =>
                        this.toggle(
                          "collapseSalesUser",
                          !this.state.collapseSalesUser
                        )
                      }
                    >
                      <span>Ver más</span> {" "}
                      <FaChevronCircleDown />
                    </div>
                    <Collapse isOpen={this.state.collapseSalesUser}>
                      <ul className="text-left">
                        <li>Usuarios independientes</li>
                        <li>Permiso de ventas</li>
                        <li>Búsqueda de productos disponibles</li>
                        <li>Venta desde diferentes sucursales</li>
                        <li>Revisión de inventario</li>
                        <li>Cierres diarios por usuario</li>
                      </ul>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md="12">
            <h2>
              <span className="color-black-main">
                Total por {this.state.priceTypes[this.state.selectedPriceTime]}
              </span>
              {this.getTotalPrice()}
              <span>$</span>
            </h2>
            <div className="discount-price-type">
              Ahorras
              <span>
                {this.getTotalPriceNoDiscount() - this.getTotalPrice()}
              </span>
              $
            </div>
          </Col>
          <Col md="12" className="margin-2rem-tb ">
            <a href="https://www.mappspdv.app/register" className="app-button custom-btn free-button">
              Pruebalo 9 dias gratis
            </a>
          </Col>
          {this.props.extraLink ? (
            <Col md="12" className="margin-2rem-tb ">
              <a href={this.props.extraLink} className="app-button custom-btn free-button2">
                {this.props.extraText}
              </a>
            </Col>
          ) : (
            ""
          )}
          <Col md="12" className="margin-2rem-tb ">
            <h5>Tienes dudas? Contáctanos</h5>
            <EmailToContact />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ProductionPrices;
