import React, { Component } from 'react';
import {  Col, Input } from 'reactstrap';
import $ from 'jquery';


class RegisterContent extends Component {

    constructor (props) {
        super(props);

        this.goToPage = this.goToPage.bind(this);
    }
    
    goToPage(componentTogo) {
        $(`#${this.props.componentId}`).addClass('hide');
        $(`#${componentTogo}`).removeClass('hide');        
    }

    getInfo() {
        const infoKeys = [];        
        const infoValues = [];        
        const infoContent = [];
                
        infoKeys.push(<h5>{this.props.subTitle2}</h5>);
        infoValues.push(<h5>{this.props.subTitle3}</h5>);

        this.props.info.forEach((infoVal) => {
            infoKeys.push(
                <p className={infoVal.keyClass ? infoVal.keyClass : ""}>{infoVal.key}</p>
            );
            infoValues.push(
                <p>{infoVal.value}</p>
            );
        });

        infoContent.push(<Col xs="6" className="f-left">
            {infoKeys}
        </Col>
        );
        infoContent.push(<Col xs="6" className="f-left">
            {infoValues}
        </Col>
        );

        var info = <div className="info">{infoContent}</div>

        return info;        
    }
  
    render() {
        return (
            <div className={`quantity-section ${this.props.bodyClass}`}>
                <h4>{this.props.title}</h4>
                <div className="quantity">
                    <h5>{this.props.subTitle1}</h5>
                    <Input type="text" placeholder="0" defaultValue={this.props.defaultValue ? this.props.defaultValue : ""} />
                </div>
                {this.getInfo()}                
            </div>
        );  
    }
}

export default RegisterContent;