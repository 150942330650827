import React, { Component } from "react";

import { Row, Container, Col, Input, Collapse, Button } from "reactstrap";
import FaChevronCircleDown from "react-icons/lib/fa/chevron-circle-down";
import { NavHashLink as HashLink } from "react-router-hash-link";

import EmailToContact from "../EmailToContact";

class RestaurantPrices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminUsers: 1,
      waiters: 1,
      kitchens: 1,
      cashiers: 1,
      collapseAdmin: false,
      collapseRestaurants: false,
      collapseWaiters: false,
      collapseCashier: false,
      priceAdmin: 16,
      priceWaiter: 13,
      priceKitchen: 13,
      priceCashier: 6,
      selectedPriceTime: 0,
      pricesTime: [
        {
          priceAdmin: 18,
          priceKitchen: 14,
          priceWaiter: 14,
          priceCashier: 5
        },
        {
          priceAdmin: 16,
          priceKitchen: 12,
          priceWaiter: 12,
          priceCashier: 3
        },
        {
          priceAdmin: 13,
          priceKitchen: 9,
          priceWaiter: 9,
          priceCashier: 1
        }
      ],
      priceTypes: ["1 Mes", "6 Meses", "1 Año"]
    };
    this.toggle = this.toggle.bind(this);
    this._handleAdminUser = this._handleAdminUser.bind(this);
    this._handleWaiters = this._handleWaiters.bind(this);
    this._handleCashier = this._handleCashier.bind(this);
    this._handleKitchens = this._handleKitchens.bind(this);
    this.selectPrice = this.selectPrice.bind(this);
  }

  toggle(name, value) {
    const valueToSet = JSON.parse(`{ "${name}": ${value} }`);

    this.setState(valueToSet);
  }

  _handleAdminUser(e) {
    if (e.target.value !== null && e.target.value !== '' && e.target.value < 1) {
      this.setState({ adminUsers: 1 });
    } else {
      this.setState({ adminUsers: e.target.value });
    }
  }

  _handleKitchens(e) {
    if (e.target.value !== null && e.target.value !== '' && e.target.value < 1) {
      this.setState({ kitchens: 1 });
    } else {
      this.setState({ kitchens: e.target.value, waiters: e.target.value });
    }
  }

  _handleCashier(e) {
    if (e.target.value !== null && e.target.value !== '' && e.target.value < 1) {
      this.setState({ cashiers: 1 });
    } else {
      this.setState({ cashiers: e.target.value });
    }
    if (e.target.value !== null && e.target.value !== '' && this.state.kitchens > e.target.value) {
      this.setState({ cashiers: this.state.kitchens });
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.selectPrice(0);
    }, 1)
  }

  _handleWaiters(e) {
    if (e.target.value !== null && e.target.value !== '' && e.target.value < 1) {
      this.setState({ waiters: 1 });
    } else {
      this.setState({ waiters: e.target.value });
    }
    if (e.target.value !== null && e.target.value !== '' && this.state.kitchens > e.target.value) {
      this.setState({ waiters: this.state.kitchens });
    }
  }

  activeButtonClass(i) {
    const className = i == this.state.selectedPriceTime ? "blue-lite" : "";

    return `app-button custom-btn ${className}`;
  }

  selectPrice(value) {
    this.setState({
      selectedPriceTime: value,
      priceAdmin: this.state.pricesTime[value].priceAdmin,
      priceCashier: this.state.pricesTime[value].priceCashier,
      priceKitchen: this.state.pricesTime[value].priceKitchen,
      priceWaiter: this.state.pricesTime[value].priceWaiter
    });
  }

  getTotalPrice() {
    let timeValue =
      this.state.selectedPriceTime == 1
        ? 6
        : this.state.selectedPriceTime == 2
          ? 12
          : 1;

    return (
      (this.state.priceWaiter * this.state.waiters +
        this.state.priceAdmin * this.state.adminUsers +
        this.state.priceKitchen * this.state.kitchens +
        this.state.priceCashier * this.state.cashiers) *
      timeValue
    );
  }

  getTotalPriceNoDiscount() {
    let timeValue =
      this.state.selectedPriceTime == 1
        ? 6
        : this.state.selectedPriceTime == 2
          ? 12
          : 1;

    return (
      (this.state.pricesTime[0].priceWaiter * this.state.waiters +
        this.state.pricesTime[0].priceAdmin * this.state.adminUsers +
        this.state.pricesTime[0].priceKitchen * this.state.kitchens +
        this.state.pricesTime[0].priceCashier * this.state.cashiers) *
      timeValue
    );
  }

  render() {
    return (
      <Container fluid className={`prices-content ${this.props.classBackground} ${this.props.color}`} id={this.props.componentId}>
        <Row>
          <Col md="12">
            <h5>{this.props.subTitle}</h5>
            <h3>{this.props.title}</h3>
            <p>
              Tenemos diferentes precios y tipos de pago, puedes elegir entre 3
              opciones de precios que varían en base a los meses que deseas
              pagar, calcula el precio del servicio registrando la información
              de cuantos usuarios administradores, restaurantes o cocinas,
              meseros y cajeros.
            </p>
            <p>Selecciona una opción de pago:</p>
          </Col>
          <Col md="12">
            <Button
              className={this.activeButtonClass(0)}
              onClick={() => {
                this.selectPrice(0);
              }}
            >
              1 Mes
            </Button>
            <Button
              className={this.activeButtonClass(1)}
              onClick={() => {
                this.selectPrice(1);
              }}
            >
              6 Meses
            </Button>
            <Button
              className={this.activeButtonClass(2)}
              onClick={() => {
                this.selectPrice(2);
              }}
            >
              1 Año
            </Button>
          </Col>
          <Col md="12">
            <h2>
              <span className={`color-black-main`}>Total</span>
              {this.getTotalPrice()}
              <span>$</span>
            </h2>
            <div className="discount-price-type">
              Ahorras
              <span>
                {this.getTotalPriceNoDiscount() - this.getTotalPrice()}
              </span>
              $
            </div>
          </Col>
          <Col md="6">
            <Container fluid>
              <Row>
                <Col xs="6" className="padding-lr-5">
                  <div className="border-card input-price">
                    <h4>Administradores</h4>
                    <Container fluid>
                      <Row>
                        <Col xs="12">
                          <h2>
                            {this.state.priceAdmin * this.state.adminUsers}
                            <span>$</span>
                          </h2>
                          <h5>Usuarios</h5>
                          <Input
                            type="number"
                            onChange={this._handleAdminUser}
                            min="1"
                            placeholder="Usuarios administradoers"
                            value={this.state.adminUsers}
                            required
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div
                      className="collapse-button"
                      onClick={() =>
                        this.toggle("collapseAdmin", !this.state.collapseAdmin)
                      }
                    >
                      <span>Ver más</span> {" "}
                      <FaChevronCircleDown />
                    </div>
                    <Collapse isOpen={this.state.collapseAdmin}>
                      <ul className="text-left">
                        <li>Usuarios independientes</li>
                        <li>Permiso administrativo</li>
                        <li>Registro de gastos</li>
                        <li>Registro de compras</li>
                        <li>Manejo de inventario por restaurante</li>
                        <li>Control de tipos de usuario</li>
                        <li>Activar usuarios para diferentes restaurantes</li>
                        <li>Cambio de contraseñas</li>
                        <li>Cierres diarios</li>
                        <li>Generación de reportes</li>
                      </ul>
                    </Collapse>
                  </div>
                </Col>
                <Col xs="6" className="padding-lr-5">
                  <div className="border-card input-price">
                    <h4>Restaurantes</h4>
                    <Container fluid>
                      <Row>
                        <Col xs="12">
                          <h2>
                            {this.state.priceKitchen * this.state.kitchens}
                            <span>$</span>
                          </h2>
                          <h5>Locales y cocinas</h5>
                          <Input
                            type="number"
                            min="1"
                            onChange={this._handleKitchens}
                            placeholder="Locales y cocinas"
                            value={this.state.kitchens}
                            required
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div
                      className="collapse-button"
                      onClick={() =>
                        this.toggle(
                          "collapseRestaurants",
                          !this.state.collapseRestaurants
                        )
                      }
                    >
                      <span>Ver más</span> {" "}
                      <FaChevronCircleDown />
                    </div>
                    <Collapse isOpen={this.state.collapseRestaurants}>
                      <ul className="text-left">
                        <li>Sucursales o locales</li>
                        <li>Impresión de tickets</li>
                        <li>Ordernes de meseros</li>
                        <li>Completar ordenes para meseros</li>
                        <li>Manejo de meseros</li>
                        <li>Activar cocina</li>
                        <li>Registro de cargas</li>
                        <li>Revisión de inventario</li>
                      </ul>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>

          <Col md="6">
            <Container fluid>
              <Row>
                <Col xs="6" className="padding-lr-5">
                  <div className="border-card input-price">
                    <h4>Meseros</h4>
                    <Container fluid>
                      <Row>
                        <Col xs="12">
                          <h2>
                            {this.state.priceWaiter * this.state.waiters}
                            <span>$</span>
                          </h2>
                          <h5>Usuarios</h5>
                          <Input
                            type="number"
                            onChange={this._handleWaiters}
                            min="1"
                            placeholder="Usuarios meseros"
                            value={this.state.waiters}
                            required
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div
                      className="collapse-button"
                      onClick={() => {
                        this.toggle(
                          "collapseWaiters",
                          !this.state.collapseWaiters
                        );
                      }}
                    >
                      <span>Ver más</span> {" "}
                      <FaChevronCircleDown />
                    </div>
                    <Collapse isOpen={this.state.collapseWaiters}>
                      <ul className="text-left">
                        <li>Usuarios independientes</li>
                        <li>Registro de pedidos</li>
                        <li>Aviso de pedidos completados</li>
                        <li>Registro de ventas</li>
                        <li>Registro de NIT y nombre para factura</li>
                        <li>Cierres diarios</li>
                      </ul>
                    </Collapse>
                  </div>
                </Col>
                <Col xs="6" className="padding-lr-5">
                  <div className="border-card input-price">
                    <h4>Cajeros</h4>
                    <Container fluid>
                      <Row>
                        <Col xs="12">
                          <h2>
                            {this.state.priceCashier * this.state.cashiers}
                            <span>$</span>
                          </h2>
                          <h5>Usuarios</h5>
                          <Input
                            type="number"
                            min="1"
                            onChange={this._handleCashier}
                            placeholder="Locales y cocinas"
                            value={this.state.cashiers}
                            required
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div
                      className="collapse-button"
                      onClick={() =>
                        this.toggle(
                          "collapseCashier",
                          !this.state.collapseCashier
                        )
                      }
                    >
                      <span>Ver más</span> {" "}
                      <FaChevronCircleDown />
                    </div>
                    <Collapse isOpen={this.state.collapseCashier}>
                      <ul className="text-left">
                        <li>Emisión de facturas</li>
                        <li>Control de inventario</li>
                        <li>Control de meseros</li>
                        <li>Cierres diarios por mesero</li>
                      </ul>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md="12">
            <h2>
              <span className="color-black-main">
                Total por {this.state.priceTypes[this.state.selectedPriceTime]}
              </span>
              {this.getTotalPrice()}
              <span>$</span>
            </h2>
            <div className="discount-price-type">
              Ahorras
              <span>
                {this.getTotalPriceNoDiscount() - this.getTotalPrice()}
              </span>
              $
            </div>
          </Col>
          <Col md="12" className="margin-2rem-tb ">
            <a href="https://www.mappspdv.app/register" className="app-button custom-btn free-button">
              Pruebalo 9 dias gratis
            </a>
          </Col>
          {this.props.extraLink ? (
            <Col md="12" className="margin-2rem-tb ">
              <a href={this.props.extraLink} className="app-button custom-btn free-button2">
                {this.props.extraText}
              </a>
            </Col>
          ) : (
              ""
            )}
          <Col md="12" className="margin-2rem-tb ">
            <h5>Tienes dudas? Contáctanos</h5>
            <EmailToContact />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default RestaurantPrices;
