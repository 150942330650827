import React, { Component } from "react";
import TitleCard from "../TitleCard";
import ProductionPrices from "../production/production-prices";
import PdvsPrices from "../pdvs/pdvs-prices-stepeer";
import RestaurantPrices from "../restaurant/restaurant-prices";

class Prices extends Component {
  render() {
    return (
      <div>
        <TitleCard
          subTitle="Aplicación"
          title="Tenemos diferentes modulos para diferentes negocios, elige el que más se adecue a tus necesidades y comencemos"
          classBackground="black0"
        />
        <PdvsPrices
          componentId="pdv-prices"
          extraLink="/pdvs"
          extraText="Mas información"
          title="Puntos de venta"
          subTitle="Aplicación"
        />
        <RestaurantPrices
          classBackground="black0 padding-tb-20vh"
          extraLink="/restaurant"
          extraText="Mas información"
          color="white"
          componentId="restaurant-prices"
          title="Restaurantes"
          subTitle="Aplicación"
        />
        <ProductionPrices
          classBackground=""
          extraLink="/production"
          extraText="Mas información"
          componentId="pdvs-p-prices"
          title="Modulo de producción"
          subTitle="Aplicación"
        />
      </div>
    );
  }
}

export default Prices;
