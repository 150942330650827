import React, { Component } from 'react';
import productionMain from '../../images/productionMain.png';
import TitleCard from '../TitleCard';
import LeftImageCard from '../LeftImageCard';
import InfoTwoContainer from '../InfoTwoContainer';
import InfoFourContainer from '../InfoFourContainer';
import factoryhouse from '../../images/factoryhouse.png';
import productionUser from '../../images/productionUser.png';
import pdvSucursal from '../../images/pdv-sucursal.png';
import pdvUsers from '../../images/pdv-users.png';

import inventory from '../../images/inventory.png';
import reportscente from '../../images/reportscente.png';
import productMenu from '../../images/product-menu.png';
import purchasesRegister from '../../images/purchases-register.png';
import reportscircle from '../../images/reportscircle.png';
import usersMulti from '../../images/users-multi.png';
import ProductionPrices from './production-prices';

class Production extends Component {

  render() {
    return (
      <div>
         <TitleCard 
          classBackground="blackMain"
          subTitle="Producción"
          title="Manejo de fábricas, producción y ventas, todo en uno" 
          text="En cada fábrica puedes administrar el desarrollo de productos, compra de insumos, gastos, manejo de inventario y el envío de productos terminados 
          a tus puntos de venta, por otro lado tienes el control de tus puntos de venta, el inventario disponible en cada uno y los usuarios cajeros o vendedores responsables 
          de cada sucursal, todos los registros se pueden realizar desde cualquier dispositivo." 
          imgSrc={productionMain}
          btnHashLink={true}
          hashLinkUrl="#production-prices"
          hashLinkText="Precios"
        />

         <LeftImageCard 
          componentId="-production-section"
          imgRigth={true}
          subTitle="Control" 
          title="Producción en varias fábricas" 
          text="Puedes administrar una o mas fábricas de manera independiente esto incluye el desarrollo de productos por fábrica, para esto puedes agregar 
          los insumos o materiales que son necesarios para desarrollar los diferentes productos en una determinada fábrica y así habilitar a los usuarios de cada fábrica, 
          por otro lado tienes toda la información de los productos e insumos disponibles por fábrica, puedes generar prospecciones para ver el material que falta para desarrollar cierta cantidad de productos,
          y también la cantidad de productos que pueden ser desarrollados con los insumos disponibles, todo en tiempo real y desde cualquier dispositivo." 
          imgSrc={factoryhouse} 
        /> 
        <LeftImageCard 
          componentId="productionUser"
          subTitle="Usuarios" 
          title="Responsables del desarrollo de productos" 
          text="Estos usuarios tienen como responsabilidad el inventarios en determinada fábrica, esto incluye el control de desarrollo de productos, los insumos o materiales disponibles, 
          control de producción para determinar la cantidad de productos que puedes crear con los insumos disponibles, así como los insumos que te faltan para el desarrollo de determinado numero de productos en la fábrica, 
          por otro lado puede registrar salidas de producto a los diferentes puntos de venta o sucursales, todos los registros se pueden realizar desde un smartphone, tablet o cualquier dispositivo y desde donde estes.
          " 
          imgSrc={productionUser} 
        /> 
        <LeftImageCard 
          componentId="devices-production-section"
          imgRigth={true}
          subTitle="Organiza" 
          title="Puntos de venta o sucursales" 
          text="Puedes administrar uno o varios puntos de venta simultáneamente, esto incluye el manejo de inventarios, la información de los 
          productos disponibles por sucursal, los usuarios que están activos en cada sucursal para realizar las ventas, registrar el cierre de cajas,  
          todo en tiempo real y desde donde estes." 
          imgSrc={pdvSucursal} 
        /> 

        <LeftImageCard 
          componentId="sales-pdv-section"
          subTitle="Controla" 
          title="Vendedores o cajeros en tus puntos de venta" 
          text="Tus empleados vendedores o cajeros tienen usuarios independientes que pueden registrar ventas desde una sucursal o punto de venta y desde cualquier dispositivos, 
          cuando estos realizan ventas, por un lado tienes el registro de las ventas y por otro la información actualizada del inventario,
          asi cuentas con el detalle de quién realizo las ventas y desde que sucursal.
          " 
          imgSrc={pdvUsers} 
        /> 

        <InfoTwoContainer 
            imgSrc={inventory}
            classBackground="black0"
            subTitle="Administración" 
            text="Cuentas con uno o varios usuarios administradores, estos tienen acceso a todos los movimientos de el negoció como ser, las fabricas disponibles,
            el productos desarrollados por fabrica, menú de productos con descripciones y precios disponibles para la futura venta, registro de gastos y compras 
            de diferentes insumos relacionados con las fabricas o puntos de venta, manejo de inventarios en diferentes puntos de venta, manejo de usuarios en las diferentes sucursales y fabricas,
            cierres de cajas, todo desde cualquier dispositivo y con cuentas privadas."
            linkText="Ir a información y precios"
            imgSrc2={reportscente}
            classBackground2="black0"
            subTitle2="Reportes" 
            text2="Toda la información del negocio en un solo lugar, puedes generar reportes de inventario por fábrica y sucursales, la información de los 
            productos que fueron creados en las diferentes fábricas, si hubo algún inconveniente con los insumos o productos, las salidas de fábrica a diferentes sucursales,
            también puedes obtener la información de los puntos de venta, cuales usuarios están activos en que punto de venta, por otro lado tienes los reportes de
            ventas realizadas por vendedor, gastos, compras del negocio, compras de insumos en diferentes fábricas o sucursales, cierres diarios, estadísticas por productos y mucho mas.
            "
            title="Manejo"
            titleBig="Todo tu negocio en tus manos"
            componentId="production-admin" />

        <InfoFourContainer 
          imgSrc={inventory}
          classBackground="black1"
          cardsInfo={[{
            text: "Menú de productos desarrollados",
            imgSrc: productMenu
          },
          {
            text: "Compras, gastos en fábricas y puntos de venta",
            imgSrc: purchasesRegister
          },{
            text: "Diferentes usuarios",
            imgSrc: usersMulti
          },{
            text: "Ventas y estadísticas",
            imgSrc: reportscircle
          }]}
          text="Ahorra tiempo, tienes diferentes maneras de manejar y controlar tu negocio, toda tu información en un solo lugar y al alcance de tu celular, decídete ya y crezcamos juntos"
          title="Control"
          titleBig="Diferentes ventajas incluidas"
          componentId="pdv-multi-info"/>

        <ProductionPrices 
          componentId="production-prices"
          title="Precios"
          subTitle="Modulo de producción"
          />
      </div>
    );
  }
}

export default Production;