import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {
  NavLink
} from "react-router-dom";

export default class TopMenu extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle tag="a">
          <NavLink to="#" toggle={this.toggle}>Soluciones <span className="icon-title">&#x25BC;</span></NavLink>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>NUESTRAS SOLUCIONES</DropdownItem>
          <DropdownItem divider />
          <DropdownItem href="/pdvs">Control de negocios</DropdownItem>
          <DropdownItem divider />
          <DropdownItem href="https://colecta.app" target="_blank" >Cobranzas y pagos online</DropdownItem>
          <DropdownItem divider />
          <DropdownItem href="https://aiudantes.com/" target="_blank" >Servicios a domicilio</DropdownItem>
          <DropdownItem divider />
          <DropdownItem href="https://dirigelo.com/" target="_blank" >Seguimineto de Proyectos</DropdownItem>
          <DropdownItem divider />
          <DropdownItem href="https://viviendaa.com/" target="_blank" >Gestion de residencias</DropdownItem>
          <DropdownItem divider />
          <DropdownItem href="https://crucemad.app/" target="_blank" >Aserraderos y barracas</DropdownItem>
          <DropdownItem divider />
        </DropdownMenu>
      </Dropdown>
    );
  }
}