import React, { Component } from 'react';
import {  Button, CardTitle, CardSubtitle } from 'reactstrap';
import $ from 'jquery';


class Init extends Component {

    constructor (props) {
        super(props);

        this.goToPage = this.goToPage.bind(this);
    }
    
    goToPage(componentTogo) {
        $(`#${this.props.componentId}`).addClass('hide');
        $(`#${componentTogo}`).removeClass('hide');        
    }
  
    render() {
        return (
            <div id={this.props.componentId} className={`init ${this.props.componentBg}`}>
                <CardTitle>{this.props.title}</CardTitle>
                <CardSubtitle>{this.props.subTitle}</CardSubtitle>                    
                <Button onClick={() => this.goToPage(this.props.componentToGoId)} className={this.props.startBtnClass}>Empezar</Button>
            </div>
        );  
    }
}

export default Init;