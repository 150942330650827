import React, { Component } from "react";
import EmailToContact from "./EmailToContact";

import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  CardText,
  CardImg
} from "reactstrap";
import { NavHashLink as HashLink } from "react-router-hash-link";
import $ from 'jquery';

class TitleCard extends Component {
  constructor(props) {
    super(props);

    this.goToHash = this.goToHash.bind(this);
  }

  goToHash(hashLink) {
    var target = $(hashLink);
    $('html, body').stop().animate({
      scrollTop: target.offset().top
    }, 1000);
  }

  goToLink(url) {
    window.open(url, '_blank');
  }
  render() {
    return (
      <Container fluid className={`${this.props.classBackground} full-height`}>
        <Container className={`main-card `}>
          <Row>
            <Col
              xs="12"
              id={this.props.componentId}
            >
              <Card className="single-card title-card">
                <CardBody>
                  <CardSubtitle>{this.props.subTitle}</CardSubtitle>
                  <CardTitle>{this.props.title}</CardTitle>
                  {this.props.imgSrc ? (
                    <CardImg top src={this.props.imgSrc} />
                  ) : (
                      ""
                    )}
                  {this.props.text ? <CardText>{this.props.text}
                    <br />
                    {this.props.text2 ? this.props.text2 : ''}
                  </CardText> : ""}

                  {this.props.btnLink ? !this.props.goToUrl ? (
                    <div className="pb-15">
                      <HashLink
                        to={this.props.linkUrl ? this.props.linkUrl : "/"}
                        className="app-button custom-btn free-button"
                      >
                        {this.props.linkText
                          ? this.props.linkText
                          : "Pruebalo gratis"}
                      </HashLink>
                    </div>
                  ) : (
                      <div className="pb-15">
                        <HashLink
                          to="#"
                          onClick={() => { this.goToLink(this.props.linkUrl) }}
                          className="app-button custom-btn free-button"
                        >
                          {this.props.linkText
                            ? this.props.linkText
                            : "Pruebalo gratis"}
                        </HashLink>
                      </div>
                    ) : (
                      ""
                    )}

                  {this.props.btnHashLink ? (
                    <div>
                      <HashLink
                        to="#"
                        onClick={() => { this.goToHash(this.props.hashLinkUrl) }}
                        className="app-button custom-btn free-button2 margin-2rem-tb "
                      >
                        {this.props.hashLinkText
                          ? this.props.hashLinkText
                          : "Info"}
                      </HashLink>
                    </div>
                  ) : (
                      ""
                    )}
                  {
                    this.props.showEmail ? <EmailToContact text={this.props.emailText} contactType={this.props.contactType} /> : ''
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default TitleCard;
