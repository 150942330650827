import React, { Component } from 'react';
import TitleCard from '../TitleCard';

import LeftImageCard from '../LeftImageCard';
import InfoTwoContainer from '../InfoTwoContainer';
import InfoFourContainer from '../InfoFourContainer';

import pdvsTop from '../../images/pdvs-top.png';
import pdvSucursal from '../../images/pdv-sucursal.png';
import pdvUsers from '../../images/pdv-users.png';
import inventory from '../../images/inventory.png';
import reportscente from '../../images/reportscente.png';
import productMenu from '../../images/product-menu.png';
import purchasesRegister from '../../images/purchases-register.png';
import userSale from '../../images/user-sale.png';
import estatistics from '../../images/estatistics.png';
import PdvsPrices from './pdvs-prices-stepeer';

class Pdvs extends Component {

  render() {
    return (
      <div>
        <TitleCard 
          btnLink={true}
          linkUrl="https://www.mappspdv.app/register/"
          goToUrl={true}
          btnHashLink={true}
          hashLinkUrl="#pdv-prices"
          hashLinkText="Precios"
          classBackground="blackMain"
          subTitle="Eficiencia y control"
          title="Admministra tus puntos de venta desde donde estes" 
          text="Crea el menu de negocio, registra la compra de los productos para tus sucursales y comienza a vender." 
          imgSrc={pdvsTop}
        />

        <LeftImageCard 
          componentId="devices-pdv-section"
          imgRigth={true}
          subTitle="Administra" 
          title="Puntos de venta o sucursales" 
          text="Puedes administrar uno o varios puntos de venta simultáneamente, esto incluye el manejo de inventarios, la información de los 
          productos disponibles por sucursal, los usuarios que están activos en cada sucursal para realizar las ventas, registrar el cierre de cajas,  
          todo en tiempo real y desde donde estes." 
          imgSrc={pdvSucursal} 
        /> 

        <LeftImageCard 
          componentId="sales-pdv-section"
          subTitle="Controla" 
          title="Vendedores o cajeros en tus puntos de venta" 
          text="Tus empleados vendedores o cajeros tienen usuarios independientes que pueden registrar ventas desde una sucursal o punto de venta y desde cualquier dispositivos, 
          cuando estos realizan ventas, por un lado tienes el registro de las ventas y por otro la información actualizada del inventario,
          asi cuentas con el detalle de quién realizo las ventas y desde que sucursal.
          " 
          imgSrc={pdvUsers} 
        /> 
        <InfoTwoContainer 
          imgSrc={inventory}
          classBackground="black0"
          subTitle="Administración" 
          text="Cuentas con uno o varios usuarios administradores, estos tienen acceso a todos los movimientos de el negoció como ser, 
          el menú de productos disponibles para la futura venta, registro de gastos y compras 
          de diferentes insumos relacionados con el negocio, manejo de inventarios en diferentes puntos de venta, manejo de usuarios en las diferentes sucursales,
          cierres de cajas, todo desde cualquier dispositivo y con cuentas privadas."
          linkText="Ir a información y precios"
          imgSrc2={reportscente}
          classBackground2="black0"
          subTitle2="Reportes" 
          text2="La parte administrativa cuenta con toda la información del negocio puede generar reportes de los movimientos de inventario por sucursales, 
          ventas realizadas por vendedor o cajero, gastos, compras del negocio, cierres de caja, estadísticas por productos y mucho más.
          "
          title="Manejo"
          titleBig="De todo tu negocio en tus manos"
          componentId="pdv-admin" />

        <InfoFourContainer 
          imgSrc={inventory}
          classBackground="black1"
          cardsInfo={[{
            text: "Menú de productos",
            imgSrc: productMenu
          },
          {
            text: "Compras y gastos",
            imgSrc: purchasesRegister
          },{
            text: "Ventas y Cierres de caja por sucursal",
            imgSrc: userSale
          },{
            text: "Estadísticas por producto",
            imgSrc: estatistics
          }]}
          text="Ahorra tiempo, tienes diferentes maneras de administrar y controlar el negocio usando solo las funciones que más te interesen"
          title="Control"
          titleBig="Diferentes ventajas incluidas"
          componentId="pdv-multi-info"/>

        <PdvsPrices 
          componentId="pdv-prices"
          title="Precios"
          subTitle="Puntos de Venta"
        />

      </div>
    );
  }
}

export default Pdvs;