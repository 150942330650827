import React, { Component } from 'react';
import {  CardSubtitle } from 'reactstrap';
import $ from 'jquery';
import FaClose from 'react-icons/lib/fa/close';


class MenuApp extends Component {

    constructor (props) {
        super(props);

        this.closeMenu = this.closeMenu.bind(this);
    }
    
    closeMenu() {
        $(`#${this.props.componentId}`).addClass('hide');
        $(`#main`).removeClass('hide');        
    }
  
    render() {
        return (
            <div id={this.props.componentId} className="main-menu custom-bg white-bg hide dental-menu">
                <div className="header custom-header">
                <h5>Menu</h5><span onClick={() => this.closeMenu()}><FaClose/></span>
                </div>  
                <div className="content custom-content init">
                <CardSubtitle>Información del conductor</CardSubtitle>
                <p>Juna Carlos Rojas</p>
                <CardSubtitle>Cargar Caminon</CardSubtitle>
                <p>Registro</p>
                <CardSubtitle>Mapa de Tiendas</CardSubtitle>
                <p>Mapa</p>
                <CardSubtitle>Registro del Ventas</CardSubtitle>
                <p>Registro del dia</p>
                <CardSubtitle>Acerca De</CardSubtitle>
                <p>MAPPS</p>
                </div>        
            </div>  
        );  
    }
}

export default MenuApp;
