import React, { Component } from "react";

import {
  Card,
  CardImg,
  CardText,
  CardBody
} from "reactstrap";

import { Col, Row, Container } from "reactstrap";
import ReactWOW from "react-wow";

class InfoFourContainer extends Component {
  getCards() {
    var renderContent = [];
    this.props.cardsInfo.forEach((card, i) => {
      renderContent.push(
        <Col key={`info-${i}`} md="4" className="margin-0-auto">
          <ReactWOW animation="pulse" delay=".3s">
            <Card className="single-card no-title">
              <CardBody>
                {card.goToUrl ? <a href={card.goToUrl} target="_blank"><CardImg top src={card.imgSrc} className="cursor-pointer" /></a> :
                  <CardImg top src={card.imgSrc} className="cursor-pointer" />}

                <CardText className={card.textClass}>{card.text}</CardText>
                {card.logoSrc ?
                  <CardImg top src={card.logoSrc} /> :
                  ""
                }
              </CardBody>
            </Card>
          </ReactWOW>
        </Col>
      );
    });

    return renderContent;
  }

  render() {
    return (
      <Container fluid className={`main-card full-height ${this.props.classBackground} bottom-20vh`} id={this.props.componentId}>
        <Container>
          <Row>
            <Col
              xs="12"
              className="titles margin-bottom-0"
            >
              <h4>{this.props.title}</h4>
              <h2>{this.props.titleBig}</h2>
            </Col>
            {this.getCards()}
            <Col xs="12" className="titles-p">
              <p>{this.props.text}</p>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default InfoFourContainer;
//<a id={`button-to-${this.props.goToId}`} className="activo">Ver Todo</a>
