import React, { Component } from "react";

import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle
} from "reactstrap";

import { Col, Row, Container } from "reactstrap";
import { NavHashLink as HashLink } from "react-router-hash-link";
import ReactWOW from "react-wow";

class TitleLink extends Component {
  render() {
    return (
      <Container fluid id={this.props.componentId}
        className={`main-card full-height ${this.props.classBackground}`}>
        <Container>
          <Row>
            <Col
              xs="12"
            >
              <ReactWOW animation="pulse" delay=".3s">
                <Card className="single-card">
                  <CardBody>
                    {this.props.subTitle ? (
                      <CardSubtitle>{this.props.subTitle}</CardSubtitle>
                    ) : (
                        ""
                      )}
                    <CardTitle className="mb-30">{this.props.title}</CardTitle>
                    <CardImg top src={this.props.imgSrc} />
                    <CardText>{this.props.text}</CardText>
                    {this.props.button2 ? (
                      <div>
                        <a
                          href="https://www.mappspdv.app/register"
                          className="app-button custom-btn free-button2"
                        >
                          Prueba gratis
                      </a>
                      </div>
                    ) : (
                        ""
                      )}
                    {this.props.button3 ? (
                      <div>
                        <a
                          href="https://calendly.com/mappsbo/30min"
                          className="app-button custom-btn free-button2"
                          target="_blank"
                        >
                          Demo gratis
                      </a>
                      </div>
                    ) : (
                        ""
                      )}
                    <a href={this.props.goToLink} className="button-link">
                      {this.props.linkText} {'>'}
                    </a>
                  </CardBody>
                </Card>
              </ReactWOW>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default TitleLink;
//<a id={`button-to-${this.props.goToId}`} className="activo">Ver Todo</a>
