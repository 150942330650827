import React, { Component } from 'react'
import TitleCard from '../TitleCard'

import delivery from '../../images/logo-demos/delivery.png'
import logisticapp from '../../images/logo-demos/logisticapp.png'
import medicine from '../../images/logo-demos/medicine.png'

import CardsList from '../CardsList'

class Demos extends Component {

  render() {
    var cards = [
        {
            goToLink: "/demos/distribuitor",
            title: "Logistica",
            text: "Controla los movimientos de tus empleados y productos.",
            imgSrc: logisticapp
        }, 
        {
            goToLink: "/demos/market",
            title: "Delivery",
            text: "Dale la facilida a tus clientes de comprar desde su celular.",
            imgSrc: delivery
        }, 
        {
            goToLink: "/demos/dentist",
            title: "Cita Medica",
            text: "Organizate para asi organizar mejor a tus clientes.",
            imgSrc: medicine
        },        
    ]

    return (
      <div>
        <TitleCard 
          classBackground="black1"
          subTitle="demos"
          title="Nos Adaptamos a tus necesidades" 
          text="Para que te informes mas sobre como funcionan nuestras apps, en esta sección tenemos algunas presentaciones en las cuales encontraras información relevante, podras ver algunas funcionalidades y asi tener una mejor idea de lo que necesitas." 
        />

        <CardsList 
            title="Lista de Demos"
            cards={cards}
        />        
      </div>
    );
  }
}

export default Demos;