import React, { Component } from 'react';
import TitleCard from '../TitleCard';

import LeftImageCard from '../LeftImageCard';
import mainGps from '../../images/main-gps.png';
import ganado from '../../images/ganado.png';
import vehicleTracking from '../../images/vehicle-tracking.jpg';

class Tracking extends Component {

  render() {
    return (
      <div>
        <TitleCard 
          btnLink={true}
          classBackground="black1"
          subTitle="Rastreo"
          title="Seguimiento en tiempo real" 
          text="Monitorea las cosas que son inportantes para ti, ten la seguridad de que estan bien solo tu puedes protegerlos, ver los caminos que toman para asi asegurarte de que estan camino a su destino." 
          imgSrc={mainGps}
        />

        <LeftImageCard 
          componentId="devices-section"
          imgRigth={true}
          subTitle="Ganado" 
          title="Collares de seguimiento" 
          text="Que tu ganado no se pierda ten un registro de cada animal y agrega diferentes caracteristicas para el control de edad peso tiempo de carner, etc. Cuando descidas que registrar puedes rastrearlo y ver en tu celular donde esta." 
          imgSrc={ganado} 
        /> 

        <LeftImageCard 
          componentId="movile-traking-section"
          subTitle="Automoviles" 
          title="Dispositivos GPS" 
          text="Asegurate de que tus vehiculos llegen a su destino y ten la seguridad de ver donde estan en tiempo real para asi tener un control y poder tomar desciciones respecto a rutas trabajadores y demas." 
          imgSrc={vehicleTracking} 
        /> 
        
      </div>
    );
  }
}

export default Tracking;