import React, { Component } from 'react'

import FooterMain from './components/FooterMain'
import MainView from './components/MainView/MainView'
import HeaderMenu from './components/HeaderMenu'

import Contact from './components/Contact/Contact'
import AppList from './components/AppList/AppList'
import Tracking from './components/Tracking/Tracking'
import Prices from './components/prices/prices'

import Support from './components/Support/Support'
import Sawmill from './components/sawmills/sawmills'
import Pdv from './components/pdvs/pdvs'

import DentalPacientApp from './components/Demos/DentalPacientApp/DentalPacientApp'
import DistributorApp from './components/Demos/DistributorApp/DistributorApp'
import MarketApp from './components/Demos/MarketApp/MarketApp'
import Demos from './components/Demos/Demos'

import './App.css'
import { Container, Row, Col } from 'reactstrap'

import {
  Route,
  BrowserRouter
} from "react-router-dom"
import Restaurant from './components/restaurant/restaurant'
import Production from './components/production/production'

class App extends Component {
  render() {   
    return (
    <BrowserRouter>
      <div className="app-content">
      <Container fluid>
        <Row>
          <Col xs="12" className="header-menu">
            <HeaderMenu />           
          </Col>
        </Row>
      </Container>
      <Route exact path="/" component={MainView}/>
      <Route exact path="/contact" component={Contact}/>
      <Route exact path="/prices" component={Prices}/>
      <Route exact path="/apps" component={AppList}/>
      <Route exact path="/tracking" component={Tracking}/>
      <Route exact path="/support" component={Support}/>
      <Route exact path="/pdvs" component={Pdv}/>
      <Route exact path="/aserraderos" component={Sawmill}/>
      <Route exact path="/restaurant" component={Restaurant}/>
      <Route exact path="/production" component={Production}/>
      <Route exact path="/demos" component={Demos}/>
      <Route exact path="/demos/distribuitor" component={DistributorApp}/>
      <Route exact path="/demos/dentist" component={DentalPacientApp}/>
      <Route exact path="/demos/market" component={MarketApp}/>
      
      <FooterMain />  
      </div>
    </BrowserRouter>
    );
  }
}

export default App;
