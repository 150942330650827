import React, { Component } from 'react';
import logo2 from '../images/log2.png';

class LogoSeparator extends Component {

  render() {
    return (
        <div className="logo-separator">
            <div className="separator">
              <img src={logo2} alt="logo"/>
            </div>            
        </div> 
    );
  }
}

export default LogoSeparator;
