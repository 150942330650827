import React, { Component } from 'react';
import TopContent from './TopContent';
import SingleMidCard from './SingleMidCard';
import TitleLink from './TitleLink';
import LeftImageCard from '../LeftImageCard';
import ThreeCards from './ThreeCards';
import InfoFourContainer from '../InfoFourContainer';
import InfoTwoContainer from '../InfoTwoContainer';

import apidata from '../../images/apidata.png';
import sawmillTop from '../../images/sawmill-top.png';
import pdv from '../../images/pdv.png';
import buildingControl from '../../images/building-control.png';
import inventory from '../../images/inventory.png';
import reportscente from '../../images/reportscente.png';
import barracasAserraderos from '../../images/barracas-aserraderos.png';
import projects from '../../images/projects.png';
import logopdv from '../../images/logopdv.png';
import tutiqetlogo from '../../images/tutiqetlogo.png';
import logovivienda from '../../images/logovivienda.png';
import logodirigelo from '../../images/logodirigelo.png';
import logobarracas from '../../images/logobarracas.png';
import logoaiudantes from '../../images/logoaiudantes.png';
import logocolecta from '../../images/logocolecta.png';

class MainView extends Component {

  render() {
    return (
      <div>
        <TopContent hideBackground={false} particles={true} />
        <SingleMidCard
          cardId="welcome-section"
          scrollToId="appsContent"
          subTitle="explora, descubre o crea"
          title="Soluciones que se adapten a tus necesidades"
          text="Estamos creado tecnología para personas y empresas que quieran lograr mayor eficiencia, 
          desarrollando junto con nuestros clientas las mejores herramientas tecnologicas." />


        <TitleLink
          imgSrc={sawmillTop}
          classBackground="black0"
          subTitle="Crear una aplicación"
          title="Validamos tus ideas"
          goToLink={"/development"}
          text="Te ayudamos con el desarrollo de tu app, plataforma o MVP, cuentanos tus necesidades para que juntos busquemos la mejor solución."
          linkText="Ver mas información"
          componentId="apps-section" />

        <InfoFourContainer
          imgSrc={inventory}
          classBackground="black1"
          cardsInfo={[{
            text: "Organizaciones de eventos",
            imgSrc: tutiqetlogo,
            textClass: "f-bold f-uppercase",
            goToUrl: 'https://tutiqet.com'
          }, {
            text: "Condominios y urbanizaciones",
            imgSrc: logovivienda,
            textClass: "f-bold f-uppercase",
            goToUrl: 'https://viviendaa.com/'
          }, {
            text: "Barracas y aserraderos",
            imgSrc: logobarracas,
            textClass: "f-bold f-uppercase",
            goToUrl: 'https://crucemad.app/'
          },
          {
            text: "Puntos de Venta",
            imgSrc: logopdv,
            textClass: "f-bold f-uppercase",
            goToUrl: 'https://www.mappspdv.app'
          },
          {
            text: "Servicios a domicilio",
            imgSrc: logoaiudantes,
            textClass: "f-bold f-uppercase",
            goToUrl: 'https://aiudantes.com/'
          },
          {
            text: "Cobros y pagos online",
            imgSrc: logocolecta,
            textClass: "f-bold f-uppercase",
            goToUrl: 'https://colecta.app/'
          }]}
          text="Estos son algunos de los proyectos que desarrollamos de la mano de nuestros clientes."
          title="ALGUNAS SOLUCIONES"
          titleBig="Aplicaciones desarrolladas por nosotros"
          componentId="solutions-section" />

        <InfoTwoContainer
          imgSrc={inventory}
          classBackground="black0"
          subTitle="COLECTA (comunicación, cobros y pagos)"
          text="Con esta herramienta puedes automatizar la comunicación con tus clientes o proveedores, enviar información
           relevante de la empresa, promociones, y recordatorios de pago, por medios digitales como WHATSAPP. 
           También te ayuda con la integración con LINKSER o la RED ENLACE por medio de CYBERSOURCE"
          linkText="Ir a información y precios"
          imgSrc2={reportscente}
          classBackground2="black0"
          subTitle2="AIUDANTES (Servicios a domicilio)"
          text2="Es una aplicación en la cual personas o empresas pueden aumentar sus ventas, ofertando servicios a domilicilio y recibir reservas para asignarlas a sus trabajadores. 
          Con esta herramienta las personas pueden encotrar los SERVICIOS QUE ESTEN CERCA de su residencia. Nos enfocamos en empresas o personas que oferten servicios de 
          cuidado de AUTOS, cuidado de PERSONAS y cuidado de CASAS."
          title="Herramientas para"
          titleBig="Desarrollo e integracion"
          componentId="apis-section" />

        <LeftImageCard
          mainView={true}
          subTitle="No te compliques"
          title="Todas las soluciones que desarrollamos funcionan en"
          importanTitle="cualquier dispositivo"
          text="Puedes empezar contandonos lo que necestas agendando una reunión con uno de nuestros expertos"
          button={
            {
              text: "Demo gratis",
              goToLink: "https://calendly.com/mappsbo/30min",
              target: '_blank'
            }
          }

          imgSrc={apidata} />

        <ThreeCards />

      </div>
    );
  }
}

export default MainView;