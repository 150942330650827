import React, { Component } from 'react';
import { Row, Col, Container, Input } from 'reactstrap';
import '../Demos.css';

import FaCheckCircle from 'react-icons/lib/fa/check-circle';
import FaSearch from 'react-icons/lib/fa/search';
import FaDetails from 'react-icons/lib/fa/edit';
import FaCheck from 'react-icons/lib/fa/check';
import FaListUl from 'react-icons/lib/fa/list-ul';
import FaMapMarker from 'react-icons/lib/fa//map-marker';
import FaPlus from 'react-icons/lib/fa/plus';
import FaClose from 'react-icons/lib/fa/close';
import FaHome from 'react-icons/lib/fa/home';

import maskiphone7 from '../../../images/logo-demos/maskiphone7.png';
import cargarcamion from '../../../images/logo-demos/truckFill.png';
import map from '../../../images/logo-demos/map.jpg';
import registro from '../../../images/logo-demos/registro.png';
import granola from '../../../images/logo-demos/granola.jpg';
import map1 from '../../../images/logo-demos/map1.png';

import Init from '../Init.js';
import MainContentHeader from '../MainContentHeader.js';
import MapMarkers from '../MapMarkers.js';
import MenuApp from '../MenuApp.js';
import RestartApp from '../RestartApp.js';

import MockupAppMap from '../MockupAppMap.js';
import CardApp from '../CardApp.js';
import QuantitySectionApp from '../QuantitySectionApp.js';
import BottomButtonsApp from '../BottomButtonsApp.js'
import RegisterContent from './RegisterContent.js'

class DistributorApp extends Component {

  render() {
    return (
      <div>              
        <Container fluid>
          <Row>     
            <Col xs="12" className="content-min-100vh">            
              <div className="app-content">              
                <div className="mask iphone7 app distributor">
                  <div className="content">
                    <Init 
                      componentId="init"
                      componentBg="custom-bg"
                      title="Logo"
                      startBtnClass="bg-skin"                      
                      subTitle="Todo a la mano"
                      componentToGoId="main" />                  

                    <MainContentHeader
                      componentId="main"
                      menuId="menu-app-ditributor"
                      componentBg="custom-bg"
                      componentHeaderClass="custom-header"
                      componentContentClass="custom-content"
                      headerText="Menu principal"
                      componentContent={
                        <div>
                          <CardApp 
                            bodyClass="card-app"
                            imgScr={cargarcamion}
                            title="Cargar Van"
                            hideComponentId="main"
                            componentTogo="load-truck"
                          />
                          <CardApp 
                            bodyClass="card-app"
                            imgScr={map}
                            title="Puntos de venta"
                            hideComponentId="main"
                            componentTogo="stores-map"
                          />
                          <CardApp 
                            bodyClass="card-app"
                            imgScr={registro}
                            title="Registro de Venta"
                            hideComponentId="main"
                            componentTogo="sold-register"
                          />
                        </div>                                          
                      }
                    />
                    
                    <MainContentHeader
                      goBackId="main"
                      componentId="load-truck"
                      menuId="menu-app-ditributor"
                      componentBg="custom-bg"
                      componentHeaderClass="custom-header"
                      componentContentClass="custom-content"
                      headerText="Cargar Van"
                      componentContent={
                        <div>
                          <QuantitySectionApp
                            hideId="load-truck"
                            toGoId="product-info"
                            title="Bolsa de Granola"
                            description="Bolsa de 500gr"
                          />
                          <QuantitySectionApp
                          hideId="load-truck"
                          toGoId="product-info"
                            title="Barra Proteinica"
                            description="Contiene"
                            defaultValue={20}
                            />
                          <QuantitySectionApp
                            hideId="load-truck"
                            toGoId="product-info"
                            title="Galletas"
                            description="Bolsa de 500gr"
                            bodyClass="no-border"
                          />
                        </div>
                      }
                      footer={
                        <BottomButtonsApp 
                          bodyClass="main-bottom-buttons bottom-btns"
                          buttonsList={[
                            {extraClass: "", title: "Confirmar", icon: <FaCheckCircle />, toHide: "load-truck", toGo: "main"},
                            {extraClass: "", title: "En Van", icon: <FaDetails />, toHide: "load-truck", toGo: "load-in-truck"},
                            {extraClass: "", title: "Buscar", icon: <FaSearch />, toHide: "load-truck", toGo: "store-sold"},
                          ]}
                        />
                      }                      
                    />

                    <MainContentHeader
                      goBackId="load-truck"                      
                      componentId="load-in-truck"
                      menuId="menu-app-ditributor"
                      componentBg="custom-bg"
                      componentHeaderClass="custom-header"
                      componentContentClass="custom-content"
                      headerText="Cargados en la Van"
                      componentContent={
                        <div>
                          <QuantitySectionApp
                            title="Barra Proteinica"
                            description="Contiene"
                            defaultValue={20}
                            />
                        </div>
                      }
                      footer={
                        <BottomButtonsApp 
                          bodyClass="main-bottom-buttons bottom-btns"
                          buttonsList={[
                            {extraClass: "", title: "Confirmar", icon: <FaCheckCircle />, toHide: "load-in-truck", toGo: "load-truck"},
                          ]}
                        />                
                      }  
                    />

                    <MainContentHeader
                      goBackId="load-truck"                      
                      componentId="product-info"
                      menuId="menu-app-ditributor"
                      componentBg="custom-bg"
                      componentHeaderClass="custom-header"
                      componentContentClass="custom-content overflow-y"
                      headerText="Producto (Granola)"
                      componentContent={
                        <div className="product-info-content">
                          <img src={granola} alt=""/>
                          <p>Descripcion</p>
                          <h3>Detalle</h3>
                          <p>Precio</p>
                          <p>U. Disponibles</p>
                          <h3>Nutrientes</h3>
                          <p>Proteinas</p>
                          <p>Fibra</p>
                        </div>
                      }
                    />

                    <MainContentHeader
                      goBackId="main"                      
                      componentId="stores-map"
                      menuId="menu-app-ditributor"
                      componentBg="custom-bg"
                      componentHeaderClass="custom-header"
                      componentContentClass="custom-content"
                      headerText="Puntos de Venta"
                      componentContent={
                        <MapMarkers 
                          toHide="stores-map"
                          goToMarker="store-sold"
                        />
                      }
                      footer={
                        <BottomButtonsApp 
                          bodyClass="main-bottom-buttons bottom-btns none-bg"
                          buttonsList={[
                            {extraClass: "color-blue-main", title: "Crear Tienda", icon: <FaPlus />, toHide: "stores-map", toGo: "new-store"},
                          ]}
                        />                
                      }
                    />

                    <MainContentHeader
                      goBackId="stores-map"                      
                      componentId="new-store"
                      menuId="menu-app-ditributor"
                      componentBg="custom-bg"
                      componentHeaderClass="custom-header"
                      componentContentClass="custom-content"
                      headerText="Elija la Ubicacion"
                      componentContent={
                        <div className="map-content new-marker">
                          <img src={map1} alt=""/>
                        </div>
                      }
                      footer={
                        <BottomButtonsApp 
                          bodyClass="main-bottom-buttons bottom-btns none-bg top-center-abs"
                          buttonsList={[
                            {extraClass: "color-blue-main", title: "Crear Aqui", icon: <FaMapMarker />, toHide: "new-store", toGo: "new-store-form"},
                          ]}
                        />                
                      }
                    />

                    <MainContentHeader
                      goBackId="new-store"                      
                      componentId="new-store-form"
                      menuId="menu-app-ditributor"
                      componentBg="custom-bg"
                      componentHeaderClass="custom-header"
                      componentContentClass="custom-content padding-lateral"
                      headerText="Llenar formulario"
                      componentContent={
                        <div className="new-store-form">
                          <FaHome />
                          <Input type="text" placeholder="Nombre De la tienda"  />                                              
                          <Input type="text" placeholder="Nombre Del Encargad@" />                                              
                          <Input type="number" placeholder="Telefono" />                                              
                          <Input type="email" placeholder="Email" />                                              
                        </div>
                      }
                      footer={
                        <BottomButtonsApp 
                          bodyClass="main-bottom-buttons bottom-btns"
                          buttonsList={[
                            {extraClass: "", title: "Guardar", icon: <FaCheckCircle />, toHide: "new-store-form", toGo: "stores-map"},
                            {extraClass: "", title: "Cancelar", icon: <FaClose />, toHide: "new-store-form", toGo: "new-store"},
                          ]}
                        />                
                      }
                    />

                    <MainContentHeader
                      goBackId="stores-map"                      
                      componentId="store-sold"
                      menuId="menu-app-ditributor"
                      componentBg="custom-bg"
                      componentHeaderClass="custom-header"
                      componentContentClass="custom-content overflow-y"
                      headerText="Tienda 1"
                      componentContent={
                        <div>
                          <RegisterContent 
                            bodyClass="register-content"
                            title="Granola"
                            subTitle1="Vendidos"
                            defaultValue="5"
                            subTitle2="En van"
                            subTitle3="- 5"
                            info={[
                                {key: "Precio", value: "100 $us."},
                                {key: "Total", value: "500 $us."},
                              ]}
                          />
                          <BottomButtonsApp 
                            bodyClass="content btns-content"
                            buttonsList={[
                              {extraClass: "", title: "Confirmar", icon: <FaCheck />},
                              {extraClass: "", title: "Detalle", icon: <FaListUl />, toHide: "store-sold", toGo: "product-info"},
                            ]}
                          />
                          <RegisterContent 
                            bodyClass="register-content"
                            title="Barra de cereal"
                            subTitle1="Vendidos"
                            defaultValue=""
                            subTitle2="En van"
                            subTitle3="- 3"
                            info={[
                                {key: "Precio", value: "70 $us."},
                                {key: "Total", value: "700 $us."},
                              ]}
                          />
                          <BottomButtonsApp 
                            bodyClass="content btns-content"
                            buttonsList={[
                              {extraClass: "", title: "Confirmar", icon: <FaCheck />},
                              {extraClass: "", title: "Detalle", icon: <FaListUl />, toHide: "store-sold", toGo: "product-info"},
                            ]}
                          />
                          <RegisterContent 
                            bodyClass="register-content"
                            title="Mani"
                            subTitle1="Vendidos"
                            defaultValue="150"
                            subTitle2="En van"
                            subTitle3="- 5"
                            info={[
                                {key: "Precio", value: "5 $us."},
                                {key: "Total", value: "750 $us."},
                              ]}
                          />
                          <BottomButtonsApp 
                            bodyClass="content btns-content"
                            buttonsList={[
                              {extraClass: "", title: "Confirmar", icon: <FaCheck />},
                              {extraClass: "", title: "Detalle", icon: <FaListUl />, toHide: "store-sold", toGo: "product-info"},
                            ]}
                          />                          
                        </div>
                      }
                      footer={
                        <BottomButtonsApp 
                        bodyClass="main-bottom-buttons bottom-btns"
                        buttonsList={[
                            {extraClass: "", title: "Confirmar", icon: <FaCheckCircle />, toHide: "store-sold", toGo: "stores-map"},
                            {extraClass: "", title: "Pedidos", icon: <FaDetails />, toHide: "store-sold", toGo: "store-sold-details"},
                            {extraClass: "", title: "Buscar", icon: <FaSearch />, toHide: "store-sold", toGo: "store-sold"},
                          ]}
                        />               
                      }
                    />

                    <MainContentHeader
                      goBackId="store-sold"                      
                      componentId="store-sold-details"
                      menuId="menu-app-ditributor"
                      componentBg="custom-bg"
                      componentHeaderClass="custom-header"
                      componentContentClass="custom-content overflow-y"
                      headerText="Ventas Tienda 1"
                      componentContent={
                        <div>
                          <RegisterContent 
                            bodyClass="register-content"
                            title="Granola"
                            subTitle1="Vendidos"
                            defaultValue="5"
                            subTitle2="En van"
                            subTitle3="- 5"
                            info={[
                                {key: "Precio", value: "100 $us."},
                                {key: "Total", value: "500 $us."},
                              ]}
                          />
                          <BottomButtonsApp 
                            bodyClass="content btns-content"
                            buttonsList={[
                              {extraClass: "", title: "Confirmar", icon: <FaCheck />},
                              {extraClass: "", title: "Detalle", icon: <FaListUl />, toHide: "store-sold-details", toGo: "product-info"},
                            ]}
                          />
                          <RegisterContent 
                            bodyClass="register-content"
                            title="Mani"
                            subTitle1="Vendidos"
                            defaultValue="150"
                            subTitle2="En van"
                            subTitle3="- 5"
                            info={[
                                {key: "Precio", value: "5 $us."},
                                {key: "Total", value: "750 $us."},
                              ]}
                          />
                          <BottomButtonsApp 
                            bodyClass="content btns-content"
                            buttonsList={[
                              {extraClass: "", title: "Confirmar", icon: <FaCheck />},
                              {extraClass: "", title: "Detalle", icon: <FaListUl />, toHide: "store-sold-details", toGo: "product-info"},
                            ]}
                          />                          
                        </div>
                      }
                      footer={
                          <BottomButtonsApp 
                            bodyClass="main-bottom-buttons bottom-btns"
                            buttonsList={[
                              {extraClass: "", title: "Confirmar", icon: <FaCheckCircle />, toHide: "store-sold-details", toGo: "store-sold"},
                              {extraClass: "", title: "Buscar", icon: <FaSearch />, toHide: "store-sold-details", toGo: "store-sold"},
                            ]}
                          />            
                      }
                      />

                    <MainContentHeader
                      goBackId="main"                      
                      componentId="sold-register"
                      menuId="menu-app-ditributor"
                      componentBg="custom-bg"
                      componentHeaderClass="custom-header"
                      componentContentClass="custom-content overflow-y"
                      headerText="Registro de venta"
                      componentContent={
                        <div>
                          <RegisterContent 
                            bodyClass="register-content"
                            title="PRODUCT 1"
                            subTitle1="En Van"
                            defaultValue="10"
                            subTitle2="Vendido"
                            subTitle3="-"
                            info={[
                                {key: "Unidades", value: "5"},
                                {key: "Precio", value: "100 $us."},
                                {key: "Total", value: "500 $us."},
                              ]}
                          />
                          <BottomButtonsApp 
                            bodyClass="content btns-content"
                            buttonsList={[
                              {extraClass: "", title: "Confirmar", icon: <FaCheck />},
                              {extraClass: "", title: "Detalle", icon: <FaListUl />, toHide: "sold-register", toGo: "product-sold-details"},
                            ]}
                          />
                          <RegisterContent 
                            bodyClass="register-content"
                            title="PRODUCT Granola"
                            subTitle1="En Van"
                            defaultValue="5"
                            subTitle2="Vendido"
                            subTitle3="-"
                            info={
                              [
                                {key: "Unidades", value: "10"},
                                {key: "Precio", value: "75 $us."},
                                {key: "Total", value: "750 $us."},
                              ]
                            }
                          />
                          <BottomButtonsApp 
                            bodyClass="content btns-content"
                            buttonsList={[
                              {extraClass: "", title: "Confirmar", icon: <FaCheck />},
                              {extraClass: "", title: "Detalle", icon: <FaListUl />, toHide: "sold-register", toGo: "product-sold-details"},
                            ]}
                          />                          
                        </div>
                      }
                      footer={
                        <BottomButtonsApp 
                          bodyClass="main-bottom-buttons bottom-btns"
                          buttonsList={[
                            {extraClass: "", title: "Confirmar", icon: <FaCheckCircle />, toHide: "sold-register", toGo: "main"},
                          ]}
                        />           
                      }
                    />

                    <MainContentHeader
                      goBackId="sold-register"                      
                      componentId="product-sold-details"
                      menuId="menu-app-ditributor"
                      componentBg="custom-bg"
                      componentHeaderClass="custom-header"
                      componentContentClass="custom-content overflow-y"
                      headerText="Venta de (Granola)"
                      componentContent={
                        <div>
                          <RegisterContent 
                            bodyClass="register-content"
                            title="Tienda 1"
                            subTitle1="Vendidos"
                            subTitle2="-"
                            defaultValue="5"
                            subTitle3="-"
                            info={
                              [
                                {key: "Precio", value: "75 $us."},
                                {key: "Total", value: "350 $us."},
                              ]
                            }
                          />
                          <BottomButtonsApp 
                            bodyClass="content btns-content"
                            buttonsList={[
                              {extraClass: "", title: "Confirmar", icon: <FaCheck />},
                              {extraClass: "", title: "Ubicacion", icon: <FaMapMarker />, toHide: "product-sold-details", toGo: "stores-map"},
                            ]}
                          />
                          <RegisterContent 
                            bodyClass="register-content"
                            title="Tienda 2"
                            subTitle1="Vendidos"
                            subTitle2="-"
                            defaultValue="1"
                            subTitle3="-"
                            info={
                              [
                                {key: "Precio", value: "75 $us."},
                                {key: "Total", value: "75 $us."},
                              ]
                            }
                          />
                          <BottomButtonsApp 
                            bodyClass="content btns-content"
                            buttonsList={[
                              {extraClass: "", title: "Confirmar", icon: <FaCheck />},
                              {extraClass: "", title: "Ubicacion", icon: <FaMapMarker />, toHide: "product-sold-details", toGo: "stores-map"},
                            ]}
                          />
                          <RegisterContent 
                            bodyClass="register-content"
                            title="Tienda 3"
                            subTitle1="Vendidos"
                            defaultValue="4"
                            subTitle2="-"
                            subTitle3="-"
                            info={
                              [
                                {key: "Precio", value: "75 $us."},
                                {key: "Total", value: "280 $us."},
                              ]
                            }
                          />
                          <BottomButtonsApp 
                            bodyClass="content btns-content"
                            buttonsList={[
                              {extraClass: "", title: "Confirmar", icon: <FaCheck />},
                              {extraClass: "", title: "Ubicacion", icon: <FaMapMarker />, toHide: "product-sold-details", toGo: "stores-map"},
                            ]}
                          />
                          <h3 className="f-right">Total 500 $us.</h3>                          
                        </div>
                      }
                      footer={
                        <BottomButtonsApp 
                          bodyClass="main-bottom-buttons bottom-btns"
                          buttonsList={[
                            {extraClass: "", title: "Confirmar", icon: <FaCheckCircle />, toHide: "product-sold-details", toGo: "sold-register"},
                          ]}
                        />         
                      }
                    />

                    <MenuApp 
                      componentId="menu-app-ditributor"
                    />

                    <div className="mask-iphone7-img">
                      <img src={maskiphone7} alt=""/>
                      <RestartApp 
                        bodyClass="iphone7"
                        initId="init"
                        componentIds={[
                          "main",
                          "product-sold-details",
                          "sold-register",
                          "store-sold-details",
                          "store-sold",
                          "new-store-form",
                          "new-store",
                          "stores-map",
                          "product-info",
                          "load-truck",
                          "product-info",
                          "load-in-truck",   
                          "menu-app-ditributor"                       
                        ]}
                      />
                    </div>
                  </div>                
                </div>                
              </div>        
            </Col>
          </Row>  
        </Container>  
        <MockupAppMap 
          headerSmallImage="Mockup de la Applicacion:"
          imageTitle="Mockup de la Para Medicos"
          modalClassName="content-img-modal "
          imgClassName="content-img-res custom-border"
        />
      </div>            
    );
  }
}

export default DistributorApp;