import React, { Component } from 'react';
import TitleCard from '../TitleCard';
import ContactForm from './ContactForm';
import SimpleMap from './SimpleMap';
import { Row, Col, Container } from 'reactstrap';
import FaFacebook from 'react-icons/lib/fa/facebook'
import FaWhatsapp from 'react-icons/lib/fa/whatsapp'
import FaPhone from 'react-icons/lib/fa/phone'

class Contact extends Component {

  render() {
    return (
      <div>
        <TitleCard subTitle="Contactanos" title="No pierdas mas tiempo y comienza a controlar tu mundo con tecnología" classBackground="black0" />                 
        <Container>
            <Row>
                <Col sm="12">
                    <ContactForm/>
                </Col>
            </Row>
        </Container>
        <Container className="letter-content contact-content">
            <Row>
                <Col sm="6">
                    <h3 className='f-bold'>
                        Calle Carmelo Rodriguez edificio Lunae º30 - Santa Cruz, Bolivia
                    </h3>
                </Col>
                <Col sm="6">
                    <h2>
                        +951 67011860
                    </h2>
                    <h2>
                        +951 72565925
                    </h2>
                    <a href="mailto:mappsbo@gmail.com?Subject=Nuevo%20contacto">
                        mappsbo@gmail.com
                    </a>
                    <a href="/" className="social-icon-content left">
                        <FaPhone />
                    </a>
                    <a href="/" className="social-icon-content left">
                        <FaWhatsapp />
                    </a>
                    <a href="/" className="social-icon-content left">
                        <FaFacebook />
                    </a>
                </Col>
            </Row>
        </Container>
        <SimpleMap />
      </div>
    );
  }
}

export default Contact;