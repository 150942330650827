import React, { Component } from 'react';
import {  CardBody, CardTitle, CardImg } from 'reactstrap';
import $ from 'jquery';

class MainContentHeader extends Component {

    constructor (props) {
        super(props);

        this.goToPage = this.goToPage.bind(this);
    }
    
    goToPage() {
        $(`#${this.props.hideComponentId}`).addClass('hide');
        $(`#${this.props.componentTogo}`).removeClass('hide');        
    }

    render() {
        return (
            <CardBody className={this.props.bodyClass}>
                <CardImg src={this.props.imgScr} onClick={() => this.goToPage()} />
                <CardTitle>{this.props.title}</CardTitle>
            </CardBody>
        );  
    }
}

export default MainContentHeader;