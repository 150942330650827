import React, { Component } from "react";

import { Row, Container, Col, Input, Collapse, Button } from "reactstrap";
import sucursal1 from "../../images/1sucursal.png";
import sucursal2 from "../../images/2sucursales.png";
import sucursal5 from "../../images/5sucursales.png";
import noInvoiceImg from "../../images/no-facturacion.png";
import invoiceImg from "../../images/facturacion.png";
import FaChevronCircleDown from "react-icons/lib/fa/chevron-circle-down";

import flagBolivia from "../../images/flag-bolivia.svg";
import $ from "jquery";
import EmailToContact from "../EmailToContact";

import FaCheck from "react-icons/lib/fa/check";

class PdvsPrices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdvs: 1,
      pdvUsers: 1,
      isOpenP: false,
      isOpenS: false,
      collapseAdmin: false,
      collapsePdv: false,
      collapseSalesUser: false,
      selectedPriceTime: 0,
      pricesTime: 100,
      invoicePrice: 50,
      priceTypes: ["Mensual", "Anual"],
      active: 4,
      hasInvoice: null
    };
    this.toggle = this.toggle.bind(this);
    this.toggleS = this.toggleS.bind(this);
    this.selectSucursal = this.selectSucursal.bind(this);
    this.activeInvoice = this.activeInvoice.bind(this);
  }

  toggle(name, value) {
    const valueToSet = JSON.parse(`{ "${name}": ${value} }`);

    this.setState(valueToSet);
  }

  toggleS() {
    this.setState({
      isOpenS: !this.state.isOpenS
    });
  }

  activeButtonClass(i) {
    const className = i == this.state.selectedPriceTime ? "blue-lite" : "";

    return `app-button custom-btn ${className}`;
  }

  getTotalPrice(timeValue) {
    var total = (this.state.pricesTime * this.state.pdvUsers +
      this.state.pricesTime +
      (this.state.hasInvoice ? this.state.invoicePrice: 0) + 100) *
      timeValue

    return total;
  }

  getTotalPriceNoDiscount(timeValue) {
    var total = (this.state.pricesTime * this.state.pdvUsers +
      this.state.pricesTime +
      (this.state.hasInvoice ? this.state.invoicePrice : 0) + 100) *
      timeValue

    return total;
  }

  selectSucursal(i) {
    this.setState({
      active: i,
      pdvUsers: i
    });
    if ($(`#pdv-invoice`).hasClass("hide")) {
      $(`#pdv-invoice`).removeClass("hide");
    }
    this.scrollTo(`#pdv-invoice`);
  }

  scrollTo(id) {
    var target = $(id);

    $("html, body")
      .stop()
      .animate(
        {
          scrollTop: target.offset().top
        },
        500
      );
  }

  activeInvoice(isActive) {
    this.setState({ hasInvoice: isActive });
    if ($(`#price-totals`).hasClass("hide")) {
      $(`#price-totals`).removeClass("hide");
    }
    this.scrollTo(`#price-totals`);
  }

  render() {
    return (
      <Container
        className={`prices-content ${this.props.classBackground}`}
        id={this.props.componentId}
      >
        <Row>
          <Col md="12">
            <h5>Plataforma</h5>
            <h2>Puntos de venta</h2>
            <h3>El precio de tu negocio en 3 pasos</h3>
            <h5>No necesitas tarjetas de credito o debito</h5>
          </Col>
          <Col md="12">
            <span className="round-icon round-icon--lg">1</span>
            <span class="separator-line"></span>
            <span class="round-icon round-icon--disable">2</span>
            <span class="separator-line"></span>
            <span class="round-icon round-icon--disable">3</span>
          </Col>
          <Col md="12">
            <h4>Selecciona las sucursales o puntos de venta que tienes:</h4>
          </Col>
          <Col className="box-price-4" xs="4">
            <div
              onClick={() => {
                this.selectSucursal(0);
              }}
              className={`border-card input-price cursor-pointer box-img-text ${this.state.active === 0 ? "active" : ""
                }`}
            >
              <img src={sucursal1} width="70%" className="padding-10px" />
              <p>1 Sucursal</p>
            </div>
          </Col>
          <Col className="box-price-4" xs="4">
            <div
              onClick={() => {
                this.selectSucursal(1);
              }}
              className={`border-card input-price cursor-pointer box-img-text ${this.state.active === 1 ? "active" : ""
                }`}
            >
              <img src={sucursal2} width="70%" className="padding-10px" />
              <p>2-4 Sucursales</p>
            </div>
          </Col>
          <Col className="box-price-4" xs="4">
            <div
              onClick={() => {
                this.selectSucursal(2);
              }}
              className={`border-card input-price cursor-pointer box-img-text ${this.state.active === 2 ? "active" : ""
                }`}
            >
              <img src={sucursal5} width="70%" className="padding-10px" />
              <p>5+ Sucursales</p>
            </div>
          </Col>
          <Col xs="12">
            <div
              className="collapse-button"
              onClick={() =>
                this.toggle("collapseSalesUser", !this.state.collapseSalesUser)
              }
            >
              <span>Ver más información</span> <FaChevronCircleDown />
            </div>
            <Collapse isOpen={this.state.collapseSalesUser}>
              <ul className="text-left">
                <li>Usuarios independientes</li>
                <li>Permiso administrativo</li>
                <li>Registro de menu de productos</li>
                <li>Registro de gastos</li>
                <li>Registro de compras</li>
                <li>Manejo de inventario por sucursales</li>
                <li>Control de usuarios por punto de venta</li>
                <li>Cambio de contraseñas</li>
                <li>Cierre de cajas</li>
                <li>Generación de reportes</li>
                <li>Estadisticas por producto</li>
                <li>Registro de ventas</li>
                <li>Inventario disponible</li>
                <li>Venta en diferentes sucursales</li>
                <li>Revisión de inventario</li>
                <li>Cierres de caja por usuario</li>
                <li>Puntos de venta</li>
                <li>Uno o mas usuarios por sucursal</li>
                <li>Lista de vendedores o cajeros</li>
                <li>Inventarios individuales</li>
                <li>Registro de cargas</li>
              </ul>
            </Collapse>
          </Col>
        </Row>
        <Row id="pdv-invoice" className="top-3vh hide">
          <Col md="12">
            <span class="round-icon round-icon--active">
              <FaCheck />
            </span>
            <span class="separator-line"></span>
            <span className="round-icon round-icon--lg">2</span>
            <span class="separator-line"></span>
            <span class="round-icon round-icon--disable">3</span>
          </Col>
          <Col md="12">
            <h4>Selecciona si quieres o no emitir facturas:</h4>
          </Col>
          <Col className="box-price-6" xs="6">
            <div
              onClick={() => {
                this.activeInvoice(false);
              }}
              className={`border-card input-price cursor-pointer box-img-text ${this.state.hasInvoice === false ? "active" : ""
                }`}
            >
              <img src={noInvoiceImg} width="70%" className="padding-10px" />
              <p>Sin Facturación</p>
            </div>
          </Col>
          <Col className="box-price-6" xs="6">
            <div
              onClick={() => {
                this.activeInvoice(true);
              }}
              className={`border-card input-price cursor-pointer box-img-text ${this.state.hasInvoice === true ? "active" : ""
                }`}
            >
              <img src={invoiceImg} width="70%" className="padding-10px" />
              <p>
                Activar Facturación
                <br />
                <span className="blue-lite">Valido para: </span>
                <img width="21px" src={flagBolivia} />
              </p>
            </div>
          </Col>
        </Row>
        <Row id="price-totals" className="top-3vh h2-title hide">
          <Col md="12">
            <span class="round-icon round-icon--active">
              <FaCheck />
            </span>
            <span class="separator-line"></span>
            <span class="round-icon round-icon--active">
              <FaCheck />
            </span>
            <span class="separator-line"></span>
            <span className="round-icon round-icon--lg">3</span>
          </Col>
          <Col md="12">
            <h4>Estos son los precios para tu negocio</h4>
          </Col>
          <Col md="12" className="margin-2rem-tb ">
            <a
              href={`https://www.mappspdv.app/register?hasInvoice=${this.state.hasInvoice}&pdvs=${this.state.pdvUsers}`}
              className="app-button custom-btn free-button"
            >
              Pruebalo 9 dias gratis
            </a>
          </Col>
          <Col md="6">
            <div className="border-card input-price">
              <h4>Pago Mensual</h4>
              <Container fluid>
                <Row>
                  <Col xs="12">
                    <h2>
                      {this.getTotalPrice(1)}
                      <span>Bs.</span>
                    </h2>
                    <div className="discount-price-type">
                      Ahorras
                      <span>
                        {" "}
                        0
                      </span>
                      Bs.
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
          <Col md="6">
            <div className="border-card input-price">
              <h4>Pago Anual</h4>
              <Container fluid>
                <Row>
                  <Col xs="12">
                    <h2>
                      {this.getTotalPrice(10)}
                      <span>Bs.</span>
                    </h2>
                    <div className="discount-price-type">
                      Ahorras
                      <span>
                        {" "}
                        {this.getTotalPrice(2)}
                      </span>
                      Bs.
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
        <Row>
          {this.props.extraLink ? (
            <Col md="12" className="margin-2rem-tb ">
              <a
                href={this.props.extraLink}
                className="app-button custom-btn free-button2"
              >
                {this.props.extraText}
              </a>
            </Col>
          ) : (
              ""
            )}
          <Col md="12" className="margin-2rem-tb ">
            <h5>Tienes dudas? Contactanos</h5>
            <EmailToContact />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PdvsPrices;
