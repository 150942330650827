import React, { Component } from 'react';
import TitleCard from '../TitleCard';
import ecomerce from '../../images/eco2.png';
import ecommerce2 from '../../images/ecommerce-dev.png';
import promotions from '../../images/promotions.png';

import LeftImageCard from '../LeftImageCard';

class AppList extends Component {

  render() {
    return (
      <div>
        <TitleCard 
          btnLink={true}
          classBackground="black1"
          subTitle="Applicaciones"
          title="Que tu negocio evolucione" 
          text="Te ayudamos a tener todo tu negocio controlado y al alcance de tu mano, nuestro objetivo es brindarte un servicio excepcional con el cual tendras todas las herramientas necesarias para que tengas un seguimiento del crecimiento y la importancia que le da la tecnologia a tu negocio." 
          imgSrc={ecomerce}
        />

        <LeftImageCard
          imgRigth={true}
          componentId="ecommerce-section"
          subTitle="E-commerce" 
          title="Que tu negocio evolucione. Vende por internet" 
          text="Tenemos diferentes soluciones para que puedas vender por interner tener un mejor control de stock y poder llegar a mas cientes." 
          imgSrc={ecommerce2} 
        /> 

        <LeftImageCard 
          subTitle="Logistica" 
          componentId="logistic-section"
          title="Controla el crecimiento de tu empresa" 
          text="Tenemos diferentes soluciones escalables al tipo de negocio que tengas, te ayudamos con el control de producción y un monitoreo preciso de todo el movimiento de tu producto." 
          imgSrc={promotions} 
        /> 
        
      </div>
    );
  }
}

export default AppList;