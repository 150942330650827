import React, { Component } from 'react';

import { InputGroup, Input, Form } from 'reactstrap';
import { NavLink } from "react-router-dom";
import $ from "jquery"
import {FaPaperPlane} from "react-icons/lib/fa"

class EmailToContact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: ''
        }
        this._handleChangeEmail = this._handleChangeEmail.bind(this);
    }

    _handleChangeEmail(e) {
        this.setState({ email: e.target.value });
    }

    render() {
        return (
            <div className={`input-content-email ${this.props.extraClass}`}>
                <Form>
                    <InputGroup>
                        <Input type="email" name="email" id="prospect-email" placeholder={this.props.text ? this.props.text : "Inicia con tu email"} value={this.state.email} onChange={this._handleChangeEmail} required />
                        <a className="button fill-gold button-small" href={this.props.linkUrl ? this.props.linkUrl : "/contact?email=" + this.state.email + "&hash=message-content&contact-type=" + (this.props.contactType || '') }>
                            <FaPaperPlane />
                        </a>
                    </InputGroup>
                </Form>
            </div>
        );
    }
}

export default EmailToContact;