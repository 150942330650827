import React, { Component } from 'react';
import $ from 'jquery';

class RestartApp extends Component {

    constructor (props) {
        super(props);

        this.restartAll = this.restartAll.bind(this);
    }

    restartAll() {
        this.props.componentIds.forEach(componentId => {
            var component = $(`#${componentId}`);
            if(!component.hasClass('hide')) {
                component.addClass('hide'); 
            }
        });
        $(`#${this.props.initId}`).removeClass('hide');
    }
  
    render() {
        return (
            <div className={`restart-app-button ${this.props.bodyClass}`} onClick={()=> this.restartAll()}>
            </div>
        );  
    }
}

export default RestartApp;